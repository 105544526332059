import React, { useState } from "react";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import instagram from "../assets/instagram.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";
import appstore from "../assets/appstore.png";
import playstore from "../assets/playstore.webp";
import phone from "../assets/phone.svg";
import email from "../assets/email.svg";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

function Footer() {
  const [subscriptionEmail, setsubscriptionEmail] = useState("");
  return (
    <footer>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex flex-col  flex-wrap bg-gradient-to-t from-slate-900 via-slate-900 to-purple-800 ">

        <div className="flex md:flex-row flex-col justify-evenly md:items-start items-start px-4 pt-[3rem] pb-[2rem] md:gap-3 gap-8">

          <div className="flex flex-col justify-center items-start gap-1 -mt-3">
            <img className="w-20" src={logo} alt="logo" />
            <div><div className="text-slate-400 pb-1">No 310, Kalyannagar, Outer Ring Rd, <br /> HRBR Layout 3rd Block, HRBR, HRBR <br /> Layout, Bengaluru, Karnataka 560043</div></div>
            <div className="flex gap-1"><img src={email} alt="phone" /> <a className="text-slate-400" href="mailto:education@juzzit.in">education@juzzit.in</a></div>
            <div className="flex gap-1"><img src={phone} alt="phone" /> <a className="text-slate-400" href="tel:+918310650351">+91 83106 50351</a></div>
          </div>

          <div className="flex flex-col gap-1">
            <div className="font-bold text-xl text-white mb-2">Services</div>
            <div>
              <div><Link className="text-slate-400" to="/e-learning">E-Learning</Link></div>
              <div><Link className="text-slate-400" to="/hackathon">Hackathon</Link></div>
              <div><Link className="text-slate-400" to="/webinar">Webinar</Link></div>
              <div><Link className="text-slate-400" to="/certification">Certifications</Link></div>
              <div><Link className="text-slate-400" to="/mentoring">Mentoring</Link></div>
              {/* <div><Link className="text-slate-400" to="/corporate-services">Corporate Services</Link></div> */}
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <div className="font-bold text-xl text-white mb-2">Company Info</div>
            <div><Link className="text-slate-400" to="/about-us">About Us</Link></div>
            {/* <div><Link className="text-slate-400" to="/leadership">Leadership</Link></div> */}
            <div><Link className="text-slate-400" to="/careers">Careers</Link></div>
            <div><Link className="text-slate-400" to="/blogs">Article & News</Link></div>
            {/* <div><Link className="text-slate-400" to="/privacy-policy">Legal Notices</Link></div> */}
          </div>


          <div className="flex flex-col gap-1">
            <div className="font-bold text-2xl text-white mb-2">Newsletter</div>
            <div className="text-slate-400 text-wrap"> Sign up our newsletter to get update information,<br /> news and free insight.</div>

            <div className="flex justify-center items-center">
              <input type="email" placeholder="Email" value={subscriptionEmail} className="bg-slate-800 text-white p-2 pl-4 my-4 rounded-full mr-5 w-full shadow-2xl border-2 border-transparent focus:border-purple-500 focus:outline-none" onChange={(e) => setsubscriptionEmail(e.target.value)} />
              <button onClick={() => {
                if (!(subscriptionEmail.includes("@" && ".") && subscriptionEmail.length > 5 && subscriptionEmail.length < 100)) {
                  toast.error("Please enter a valid email address");
                  return;
                }
                toast.success("Subscribed Successfully");
                setsubscriptionEmail("");
              }} className="bg-purple-500 py-2 px-2 text-white font-lg rounded-full shadow-xl w-1/3 h-fit">Sign Up</button>
            </div>

          </div>

        </div>

        {/* <div className="flex flex-wrap justify-evenly items-center py-16 px-[2rem] bg-slate-800 md:mx-[5rem] mx-11 gap-11 rounded-xl">
          <div className="text-2xl font-extrabold text-white text-wrap">
            Ready to learn on-the-go? Download our mobile app <br />and start learning anytime, anywhere!
          </div>
          <div className="flex flex-wrap justify-center gap-5">
            <div>
              <img className="w-40 h-16 cursor-pointer" src={playstore} alt="playstore image" />
            </div>
            <div>
              <img className="w-40 h-16 cursor-pointer" src={appstore} alt="appstore image" />
            </div>
          </div>
        </div> */}

        <div className="flex flex-wrap justify-between items-center py-10 md:px-40 px-10 gap-3">
          <div className="flex justify-center items-center md:gap-2 gap-2">
            <div className=" bg-slate-800 rounded-full cursor-pointer"><img className="md:w-10 md:h-10 h-5 w-5 md:p-1.5" src={facebook} alt="facebook icon" /></div>
            <div className=" bg-slate-800 rounded-full cursor-pointer"><img className="md:w-10 md:h-10 h-5 w-5 md:p-1.5" src={instagram} alt="instagram icon" /></div>
            <div className=" bg-slate-800 rounded-full cursor-pointer"><img className="md:w-10 md:h-10 h-5 w-5 md:p-1.5" src={twitter} alt="twitter icon" /></div>
            <div className=" bg-slate-800 rounded-full cursor-pointer"><img onClick={() => window.open("https://www.linkedin.com/company/juzz-it", "_blank")} className="md:w-10 md:h-10 h-5 w-5 md:p-1.5" src={linkedin} alt="linkedin icon" /></div>
            <div className=" bg-slate-800 rounded-full cursor-pointer"><img className="md:w-10 md:h-10 h-5 w-5 md:p-1.5" src={youtube} alt="youtube icon" /></div>
          </div>
          <div className="text-slate-500">
            &copy; 2025 JUZZ-IT. All rights reserved. Powered by JUZZ-IT.
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;