import React from "react";
import Header from "./Header";
import Courses from "./Course";
import Testimony from "./Testimony";
import WhyChooseUs from "./WhyChooseUs";
import CommonQuestions from "./CommonQuestions";
import NeedHelp from "./NeedHelp";
import TermsAndConditions from "./TermsAndConditions";
import Brand from "./Brand";
import PopularCourse from "./PopularCourse";

function Home() {
  return (
    <div style={{ backgroundColor: "#000", color: "#fff" }}>
      {/* Header */}
      <Header />

      {/* Courses Section */}
      <Courses />

      <Brand />
      <PopularCourse />
      {/* Other sections */}
      {/* Testimony Section */}
      <div className="container-fluid py-5">
        <Testimony />
      </div>

      {/* Why Choose Us Section */}
      <div className="container-fluid py-5">
        <WhyChooseUs />
      </div>
      {/* Need Help Section */}
      <NeedHelp />

      {/* Common Question Section */}
      <CommonQuestions />

      {/* Terms and Conditions Section */}
      <TermsAndConditions />

    </div>
  );
}
export default Home;
