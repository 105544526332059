import React from "react";
import { Link } from "react-router-dom";

function TermsAndPrivacy() {
  return (
    <div
      style={{
        backgroundColor: "#000", // Black background
        color: "#fff", // White text
        padding: "50px 0",
        margin: "0",
        width: "100%",
      }}
    >
      <div style={{ padding: "0 5%" }}>
        <h3 className="text-center mb-4" style={{ fontWeight: "bold" }}>
          Terms & Conditions and Privacy Policy
        </h3>

        <p style={{ fontSize: "1.1rem", lineHeight: "1.8", textAlign: "center" }}>
          By using our platform, you agree to the following Terms and Conditions and acknowledge our Privacy Policy.
        </p>

        <div style={{ textAlign: "justify", fontSize: "1rem", padding: "0 5%" }}>
          <p>
            These Terms & Conditions (“Terms” or “ToU”) are JUZZ-IT (along with its successors, subsidiaries, permitted assignees, management, support/sales team, employees, consultants, and all persons claiming through them and under them and referred to as the “institutions”, JUZZ-IT”, “us”, “we”, or “our”) and its Users and/or its Minor Users.
          </p>

          <p>
            <Link to="/terms-conditions" style={{ color: "#f8c102", textDecoration: "underline" }}>
              Read Full Terms & Conditions
            </Link>
          </p>
        </div>

        <p style={{ marginTop: "20px", fontSize: "1rem", textAlign: "center" }}>
          For more details, please read our full Terms & Privacy Policy or contact our support team.
        </p>
      </div>
    </div>
  );
}

export default TermsAndPrivacy;
