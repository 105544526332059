import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/main-logo.png";
import { FaBook, FaPlusCircle, FaUsers, FaUserCog, FaSignOutAlt } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const Navbar = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mobileCoursesOpen, setMobileCoursesOpen] = useState(false);
  const [mobilePagesOpen, setMobilePagesOpen] = useState(false);
  const [courseCategories, setCourseCategories] = useState([]);
  const [pages, setPages] = useState([]);
  const [user, setUser] = useState(null);
  // New state variables for profile dropdown menus
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [mobileProfileOpen, setMobileProfileOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Simulate fetching categories and pages from an API
  useEffect(() => {
    const fetchData = async () => {
      // Replace this with a real API call when backend is ready
      const fetchedCourses = [
        "Full Stack",
        "Data Science",
        "Business Analyst",
        "Hybrid Electric Vehicle Design and Analysis",
        "Electric Vehicle Design and Analysis",
        "Cybersecurity",
        "Digital Marketing",
        "HR Management",
        "AI & ML",
        "Gen AI",
        "Cloud Computing and DevOps",
      ];
      const fetchedPages = ["About Us", "Hackathon", "FAQ", "Blogs", "Privacy & Policy"];

      setCourseCategories(fetchedCourses);
      setPages(fetchedPages);
    };

    fetchData();
  }, []);

  // Check if a user is logged in (simulate using localStorage)
  useEffect(() => {

    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(`${BACKEND_URL}/api/v1/auth/me`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setUser(response.data);
          // console.log("User is logged in:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      console.log("No token found. User is not logged in.");
    }
  }, [location]);

  // Simple logout handler
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BACKEND_URL}/api/v1/auth/logout`, {}, {
        headers: {
          Authorization: token
        },
      });

      toast.success(response.data?.message || "Logged Out successfully!");

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      setUser(null);
      navigate("/");

    } catch (error) {
      toast.error(error.response?.data?.error || "Error while logout!!")
    }
  };

  // Helper function to get initials from email
  const getInitials = (email) => {
    if (!email) return "";
    // Extract the part before the "@" symbol
    const namePart = email.split("@")[0];
    // Split by dots or underscores if needed and take the first letter of each part
    const parts = namePart.split(/[._]/);
    const initials = parts.map((part) => part.charAt(0).toUpperCase()).join("");
    return initials;
  };

  const isActive = (path) => {
    return location.pathname === path
      ? "text-[#a855f7]"
      : "text-white hover:text-white/80";
  };

  let profileMenuItems = [];
  if (user && user.role === "teacher") {
    profileMenuItems = [
      { label: "My Courses", link: "/my-courses", icon: <FaBook className="inline size-5 mr-2" /> },
      { label: "Add Courses", link: "/add-courses", icon: <FaPlusCircle className=" size-5 inline mr-2" /> },
      { label: "My Users", link: "/my-users", icon: <FaUsers className="inline size-5 mr-2" /> },
      { label: "Profile Settings", link: "/profile-settings", icon: <FaUserCog className="inline size-5 mr-2" /> },
      { label: "Support", link: "/support", icon: <BiSupport className="inline size-5 mr-2" /> },
      { label: "Logout", action: handleLogout, icon: <FaSignOutAlt className="inline size-5 mr-2" /> },
    ];
  } else if (user && user.role === "student") {
    profileMenuItems = [
      { label: "My Courses", link: "/student-dashboard", icon: <FaBook className="inline size-5 mr-2" /> },
      { label: "Profile Settings", link: "/profile-settings", icon: <FaUserCog className="inline size-5 mr-2" /> },
      { label: "Support", link: "/support", icon: <BiSupport className="inline size-5 mr-2" /> },
      { label: "Logout", action: handleLogout, icon: <FaSignOutAlt className="inline size-5 mr-2" /> },
    ];
  }
  return (
    <nav className="top-0 z-50 w-full bg-[#12141D]">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container mx-auto px-2">
        <div className="flex h-20 items-center justify-between">
          <Link className="flex items-center gap-2" to="/">
            <img
              src={Logo || "/placeholder.svg"}
              alt="JUZZ-IT Logo"
              className="h-12 w-auto md:h-16 md:w-auto"
              priority="true"
            />
            <h1 className="text-2xl font-bold text-white hidden sm:block">
              JUZZ-IT
            </h1>
          </Link>
          <div className="hidden md:block">
            <ul className="flex items-center font-medium space-x-4 lg:space-x-8 text-sm mb-0">
              <li>
                <Link to="/" className={isActive("/")}>
                  Home
                </Link>
              </li>
              <li className="group relative">
                <button className="text-white hover:text-white/80 focus:outline-none inline-flex items-center">
                  Courses
                  <svg
                    className="w-2.5 h-2.5 ms-3 transition-transform group-hover:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div className="absolute left-0 mt-6 w-60 rounded-lg bg-white shadow-lg opacity-0 invisible group-hover:!opacity-100 group-hover:!visible transition-all duration-300 ease-in-out z-50">
                  <ul className="py-2 pl-0">
                    {courseCategories.map((category, index) => (
                      <li key={index}>
                        <Link
                          to={`/courses/${index + 1}`}
                          className="block px-2 text-center py-3 hover:bg-[#ac5ef5] !text-black hover:!text-white transition-colors duration-200"
                        >
                          {category}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/hackathon" className={isActive("/hackathon")}>
                  Webinar
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className={isActive("/contact-us")}>
                  Contact Us
                </Link>
              </li>
              <li className="group relative">
                <button className="text-white hover:text-white/80 focus:outline-none inline-flex items-center">
                  Pages
                  <svg
                    className="w-2.5 h-2.5 ms-3 transition-transform group-hover:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div className="absolute left-0 mt-6 w-44 rounded-lg bg-white shadow-lg opacity-0 invisible group-hover:!opacity-100 group-hover:!visible transition-all duration-300 ease-in-out z-50">
                  <ul className="!py-2 text-sm pl-0">
                    {pages.map((page, index) => (
                      <li key={index}>
                        <Link
                          to={`/${page.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-")}`}
                          className="block text-center px-2 py-3 hover:bg-[#ac5ef5] text-black hover:!text-white transition-colors duration-200"
                        >
                          {page}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>

              {/* Desktop auth section */}
              {user ? (
                <li className="relative">
                  <button
                    onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
                    className="flex items-center focus:outline-none"
                  >
                    <div className="bg-violet-600 text-lg text-white rounded-full h-10 w-10 flex items-center justify-center">
                      {getInitials(user.email)}
                    </div>
                  </button>
                  {profileDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 rounded-lg bg-white shadow-lg z-50">
                      <ul className="py-2 pl-0">
                        {profileMenuItems.map((item, index) => (
                          <li key={index}>
                            {item.link ? (
                              <Link
                                onClick={() => setProfileDropdownOpen(false)}
                                to={item.link}
                                className="px-4 py-3 text-black hover:bg-[#ac5ef5] hover:!text-white transition-colors duration-200 flex items-center"
                              > {item.icon}
                                {item.label}
                              </Link>
                            ) : (
                              <button
                                onClick={() => {
                                  setProfileDropdownOpen(false);
                                  item.action();
                                }}
                                className="w-full text-left px-4 py-3 text-black hover:bg-[#ac5ef5] hover:!text-white transition-colors duration-200 flex items-center"
                              >
                                {item.icon}
                                {item.label}
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ) : (
                <>
                  <li>
                    <Link
                      to="/login"
                      className="px-4 py-2 rounded-full hover:text-white !border text-violet-500 bg-transparent hover:!bg-violet-700 transition duration-200 !border-violet-800"
                    >
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/signup"
                      className="bg-violet-600 rounded-full text-white px-4 py-2 hover:bg-violet-700"
                    >
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-white p-2">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMenuOpen ? "block" : "hidden"} md:hidden bg-[#1c1d1f] min-h-screen`}>
        <div className="px-4 py-8 space-y-6 text-center">
          {/* Mobile auth section */}
          {user ? (
            <div className="relative">
              <button
                onClick={() => setMobileProfileOpen(!mobileProfileOpen)}
                className="flex justify-center gap-3 items-center mx-auto"
              >
                <div className="bg-violet-600 font-bold text-lg text-white rounded-full h-10 w-10 flex items-center justify-center">
                  {getInitials(user.email)}
                </div>
                <div className="text-white text-2xl font-bold">{user.fullName}</div>
              </button>
              {mobileProfileOpen && (
                <ul className="mt-2 bg-[#1c1d1f] rounded-md shadow-lg py-2 pl-0 flex flex-col items-center">
                  {profileMenuItems.map((item, index) => (
                    <li key={index}>
                      {item.link ? (
                        <Link
                          onClick={() => {
                            setMobileProfileOpen(false);
                            setIsMenuOpen(false);
                          }}
                          to={item.link}
                          className="block px-4 py-2 text-white hover:bg-[#ac5ef5] transition-colors duration-200"
                        >
                          {item.icon}
                          {item.label}
                        </Link>
                      ) : (
                        <button
                          onClick={() => {
                            setMobileProfileOpen(false);
                            setIsMenuOpen(false);
                            item.action();
                          }}
                          className="w-full text-left px-4 py-2 text-white hover:bg-[#ac5ef5] transition-colors duration-200"
                        >
                          {item.icon}
                          {item.label}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className={`block text-lg font-medium ${isActive("/login")}`}
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="block bg-[#a855f7] text-white text-lg font-medium py-3 px-4 rounded text-center"
              >
                Sign Up
              </Link>
            </>
          )}
          <Link to="/" className={`block text-lg font-medium ${isActive("/")}`}>
            Home
          </Link>
          <div className="space-y-2">
            <button
              onClick={() => setMobileCoursesOpen(!mobileCoursesOpen)}
              className="flex justify-center gap-1 items-center w-full text-white text-lg font-medium"
            >
              Courses
              <svg
                className={`w-4 h-4 transition-transform ${mobileCoursesOpen ? "rotate-180" : ""}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {mobileCoursesOpen && (
              <ul className="py-1 space-y-2 pl-0">
                {courseCategories.map((category, index) => (
                  <li key={index}>
                    <Link to={`/courses/${index + 1}`} className="block text-white text-base">
                      {category}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <Link to="/webinar" className={`block text-lg font-medium ${isActive("/webinar")}`}>
            Webinar
          </Link>
          <Link to="/contact-us" className={`block text-lg font-medium ${isActive("/contact-us")}`}>
            Contact Us
          </Link>
          <div className="space-y-2">
            <button
              onClick={() => setMobilePagesOpen(!mobilePagesOpen)}
              className="flex justify-center gap-1 items-center w-full text-white text-lg font-medium text-center"
            >
              Pages
              <svg
                className={`w-4 h-4 transition-transform ${mobilePagesOpen ? "rotate-180" : ""}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {mobilePagesOpen && (
              <ul className="py-1 space-y-2 pl-0">
                {pages.map((page, index) => (
                  <li key={index}>
                    <Link
                      to={`/${page.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-")}`}
                      className={`block text-base ${isActive(
                        `/${page.toLowerCase().replace(/ & /g, "-").replace(/ /g, "-")}`
                      )}`}
                    >
                      {page}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>


        </div>
      </div>
    </nav>
  );
};

export default Navbar;


