import { FaCode, FaCar, FaBolt, FaUserTie, FaRobot, FaChartLine, FaMoneyBillWave, FaHospital, FaBuilding, FaCloud, FaBrain } from "react-icons/fa";
import { AiOutlineFundProjectionScreen, AiOutlineAppstore, AiOutlineSafety } from "react-icons/ai";
import { RiStackFill, RiComputerLine } from "react-icons/ri";

const classes = [
    { icon: <FaCode />, title: "Full Stack", desc: "Master front-end and back-end development to build dynamic web applications." },
    { icon: <AiOutlineAppstore />, title: "Data Science", desc: "Analyze data, build AI models, and create predictive solutions." },
    { icon: <AiOutlineFundProjectionScreen />, title: "Business Analyst", desc: "Learn to analyze business processes, improve efficiency, and make data-driven decisions." },
    { icon: <FaCar />, title: "Hybrid Electric Vehicle Design and Analysis", desc: "Understand hybrid vehicle architecture, battery systems, and energy management." },
    { icon: <FaBolt />, title: "Electric Vehicle Design and Analysis", desc: "Explore EV powertrains, battery technologies, and sustainability solutions." },
    { icon: <AiOutlineSafety />, title: "Cybersecurity", desc: "Learn ethical hacking, threat detection, and secure software development." },
    { icon: <FaChartLine />, title: "Digital Marketing", desc: "Master SEO, social media marketing, PPC, and content strategy." },
    { icon: <FaUserTie />, title: "HR Management", desc: "Understand talent acquisition, performance management, and organizational behavior." },
    { icon: <FaRobot />, title: "AI & ML", desc: "Learn machine learning algorithms, deep learning, and AI model deployment." },
    { icon: <FaBrain />, title: "Gen AI", desc: "Explore generative AI models, applications, and advancements in AI-driven creativity." },
    { icon: <FaCloud />, title: "Cloud Computing and DevOps", desc: "Learn cloud services, CI/CD pipelines, and infrastructure automation." },
    { icon: <FaMoneyBillWave />, title: "Accounts & Finance", desc: "Understand financial statements, investment strategies, and accounting principles." },
    { icon: <FaHospital />, title: "Medical Technology", desc: "Explore innovations in healthcare, medical devices, and digital health solutions." },
    { icon: <FaBuilding />, title: "High-Rise Building Design & Analysis", desc: "Learn structural engineering principles for skyscraper construction." },
    { icon: <RiStackFill />, title: "MERN Stack", desc: "Build full-stack web applications using MongoDB, Express, React, and Node.js." },
    { icon: <RiComputerLine />, title: "DSA & System Design", desc: "Master data structures, algorithms, and scalable system architecture design." },
];

const PopularCourse = () => {
    return (
        <section className="bg-black py-14 px-6 flex justify-center">
            <div className="w-full text-start md:p-24 p-10">
                <h2 className="text-3xl md:text-4xl font-bold text-slate-300">Our Popular Courses</h2>
                <div className="w-20 bg-slate-600 h-[0.010rem] mb-10"></div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {classes.map((item, index) => (
                        <div key={index} className="flex flex-col items-start gap-3">
                            <div className="text-purple-400 text-3xl pb-2">{item.icon}</div>
                            <div>
                                <h3 className="text-lg font-semibold text-slate-200">{item.title}</h3>
                                <p className="text-slate-500">{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PopularCourse;
