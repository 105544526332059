import { useState, useEffect } from "react";
import axios from "axios";
import {
  FiUser,
  FiMail,
  FiPhone,
  FiUserCheck,
  FiEdit2,
  FiSave,
  FiX,
  FiTrash2,
  FiLock
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const ProfileSettings = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    number: "",
  });
  const [error, setError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  // Fetch user data on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("No token found. User is not logged in.");
      setError("No token found. Please log in.");
      return;
    }

    axios
      .get(`${BACKEND_URL}/api/v1/auth/me`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setUser(response.data);
        // Set initial form data for editing
        setFormData({
          fullName: response.data.fullName || "",
          email: response.data.email || "",
          number: response.data.number || "",
        });
      })
      .catch((err) => {
        console.log("Error fetching user data:", err);
        setError("Error fetching user data");
      });
  }, []);

  // Handle changes in input fields during editing
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle saving the updated data
  const handleSaveChanges = () => {
    const token = localStorage.getItem("token");
    axios
      .put(`${BACKEND_URL}/api/v1/users/${user._id}`, formData, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log("User updated:", response.data);
        setUser(response.data); // Update the displayed user data
        setIsEditing(false);
        setUpdateError(null);
      })
      .catch((err) => {
        console.log("Error updating user data:", err);
        setUpdateError("Error updating user data");
      });
  };

  // Cancel editing and reset formData to the current user values
  const handleCancel = () => {
    setFormData({
      fullName: user.fullName || "",
      email: user.email || "",
      number: user.number || "",
    });
    setIsEditing(false);
    setUpdateError(null);
  };

  // Open the delete confirmation modal
  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  // Confirm account deletion
  const confirmDeleteAccount = () => {
    const token = localStorage.getItem("token");
    axios
      .delete(`${BACKEND_URL}/api/v1/users/${user._id}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log("Account deleted:", response.data);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        // Redirect to homepage or login page after deletion
        window.location.href = "/";
      })
      .catch((err) => {
        console.error("Error deleting account:", err);
        setError("Error deleting account");
      });
    closeDeleteModal();
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <p className="text-lg text-red-500">{error}</p>
      </div>
    );
  }

  // While waiting for the user data, you can display a loader or nothing
  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-[#1B2028] flex items-center justify-center p-4">
      <div className="bg-[#1B2028] rounded-xl shadow-lg p-8 max-w-4xl w-full !border border-[#30363d]">
        <div className="flex flex-col sm:flex-row items-center justify-between mb-10">
          <div className="flex items-center gap-4 mb-8 sm:mb-0">
            <div className="w-24 h-24 rounded-full bg-violet-600 flex items-center justify-center text-white text-2xl font-medium">
              {getInitials(user.fullName)}
            </div>
            <div>
              <h2 className="text-3xl font-semibold text-white mb-1">
                {user.fullName}
              </h2>
              <p className="text-[#8A94A6] flex mt-1 items-center"><FiUserCheck className="text-[#8A94A6] mr-2" size={18} />{user.role}</p>
            </div>
          </div>

          {/* Delete Account Button */}
          <div className="flex flex-col gap-3 justify-center">
            <button
              onClick={() => navigate("/resetpassword")}
              className="flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              <FiLock className="mr-2" size={20} />
              Reset Password
            </button>
            <button
              onClick={openDeleteModal}
              className="flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              <FiTrash2 className="mr-2" size={20} />
              Delete Account
            </button>
          </div>
        </div>

        {/* Name */}
        <div className="mb-8 flex flex-col sm:flex-row sm:items-center text-xl">
          <div className="flex items-center ">
            <FiUser className="text-violet-400 mr-2" size={25} />
            <span className="font-medium text-[#8A94A6]">Name:</span>
          </div>
          {isEditing ? (
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              className="sm:ml-8 p-2 rounded bg-[#2A3441] text-white w-full border border-[#363E4A] focus:outline-none focus:border-blue-500"
            />
          ) : (
            <p className="sm:ml-3 mb-0 text-[#CBD5E1]">{user.fullName}</p>
          )}
        </div>

        {/* Email */}
        <div className="mb-8 flex flex-col  sm:flex-row sm:items-center text-xl">
          <div className="flex items-center ">
            <FiMail className="text-violet-400 mr-2" size={25} />
            <span className="font-medium text-[#8A94A6]">Email:</span>
          </div>
          {isEditing ? (
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="sm:ml-8 p-2 rounded bg-[#2A3441] text-white w-full border border-[#363E4A] focus:outline-none focus:border-blue-500"
            />
          ) : (
            <p className="sm:ml-3 mb-0 text-[#CBD5E1]">{user.email}</p>
          )}
        </div>

        {/* Number */}
        <div className="mb-8 flex flex-col sm:flex-row sm:items-center text-xl">
          <div className="flex items-center ">
            <FiPhone
              className="text-violet-600 dark:text-violet-400 mr-2"
              size={25}
            />
            <span className="font-medium text-[#8A94A6]">Number:</span>
          </div>
          {isEditing ? (
            <input
              type="text"
              name="number"
              value={formData.number}
              onChange={handleInputChange}
              className="sm:ml-8 p-2 rounded bg-[#2A3441] text-white w-full border border-[#363E4A] focus:outline-none focus:border-blue-500"
            />
          ) : (
            user.number && (
              <p className="sm:ml-3 mb-0 text-[#CBD5E1]">+91-{user.number}</p>
            )
          )}
        </div>


        {/* Display update error if any */}
        {updateError && (
          <p className="text-red-500 mb-8 text-center">{updateError}</p>
        )}

        {/* Edit/Save Buttons */}
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
          {isEditing ? (
            <>
              <button
                onClick={handleSaveChanges}
                className="flex items-center px-6 py-2 bg-[#3662E3] hover:bg-[#2D4FB3] text-white rounded-lg transition-colors"
              >
                <FiSave className="mr-2" />
                Save Changes
              </button>
              <button
                onClick={handleCancel}
                className="flex items-center px-6 py-2 bg-[#2A3441] hover:bg-[#363E4A] text-white rounded-lg transition-colors"
              >
                <FiX className="mr-2" />
                Cancel
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center px-6 py-2 bg-[#3662E3] hover:bg-[#2D4FB3] text-white rounded-lg transition-colors"
            >
              <FiEdit2 className="mr-2" />
              Edit Profile
            </button>
          )}
        </div>

        {/* Tailwind CSS Modal for Account Deletion */}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Overlay */}
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={closeDeleteModal}
            ></div>
            {/* Modal */}
            <div className="bg-[#1B2028] rounded-xl p-6 z-50 max-w-md w-full mx-4 !border border-[#363E4A]">
              <h3 className="text-xl font-semibold mb-8 text-white">
                Confirm Account Deletion
              </h3>
              <p className="text-[#8A94A6] mb-6">
                Are you sure you want to delete your account? This action cannot
                be undone.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={closeDeleteModal}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteAccount}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-500"
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
