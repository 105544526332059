import testimonialBg from "../assets/testimonial-bg.jpg";
import quoteIcon from "../assets/quote.png";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const testimonials = [
  {
    name: "Akhila Reddy",
    feedback:
      "Thank you for the entire team who have been guiding me from the beginning to this stage. Thank you everyone",
    image: "/images/Akhila Reddy.png",
  },
  {
    name: "Aqib Mohammed",
    feedback:
      "I highly recommend Juzz It for anyone looking to upskill in tech. The platform is easy to use, and the support is excellent!",
    image: "/images/Aqib Mohammed.png",
  },
  {
    name: "Kiran Swami",
    feedback:
      "Juzz It's courses helped me land my dream job. The practical projects and certifications are extremely valuable.",
    image: "/images/Kiran Swami.png",
  },
  {
    name: "Nagaraj K",
    feedback:
      "The instructors are knowledgeable and the community support is outstanding. I've learned so much in just a few months.",
    image: "/images/Nagaraj K.png",
  },
  {
    name: "Sunil B",
    feedback:
      "What sets Juzz It apart is their hands-on approach to learning. The real-world projects really helped cement my understanding.",
    image: "/images/Sunil B.png",
  },
  {
    name: "G Suresh",
    feedback:
      "I'm impressed by how well-structured the courses are. The step-by-step learning path made my transition into tech much smoother.",
    image: "/images/G Suresh.png",
  },
  {
    name: "Divakar Reddy",
    feedback:
      "The career support and guidance from Juzz It has been invaluable. They don't just teach you skills, they help you succeed.",
    image: "/images/Divakar Reddy.png",
  },
];

export default function Testimony() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDirection(1);
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className="relative min-h-screen w-full overflow-hidden bg-gradient-to-br from-purple-900 to-black"
      style={{
        backgroundImage: `url(${testimonialBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-black/60"></div>

      <div className="relative z-10 mx-auto max-w-6xl px-4 py-20">
        <div className="relative max-w-xl rounded-2xl bg-black/30 p-8 backdrop-blur-sm bg-gradient-to-br from-purple-900/80 to-black/90">
          <div className="mb-8 mt-4">
            <h3 className="mb-4 text-lg font-medium uppercase tracking-wider text-gray-300">
              Testimonial
            </h3>
            <h2 className="text-3xl font-bold text-white sm:text-4xl">
              What alumni say about us
            </h2>
          </div>
          <hr className="h-px my-4 bg-white border-0" />

          <div className="absolute -top-6 left-8">
            <img
              src={quoteIcon || "/placeholder.svg"}
              alt="Quote"
              width={80}
              height={60}
              className="opacity-90"
            />
          </div>

          <motion.div
            key={currentIndex}
            initial={{ x: direction * 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -direction * 100, opacity: 0 }}
            transition={{ type: "tween", duration: 0.5 }}
          >
            <blockquote className="mt-6 text-xl leading-relaxed text-gray-200 sm:text-xl">
              {testimonials[currentIndex].feedback}
            </blockquote>

            <div className="mt-8 flex items-center gap-4">
              <div className="h-12 w-12 overflow-hidden rounded-full">
                <img
                  src={testimonials[currentIndex].image || "/placeholder.svg"}
                  alt={testimonials[currentIndex].name}
                  className="h-full w-full object-cover"
                />
              </div>
              <div>
                <h4 className="font-semibold text-white">
                  {testimonials[currentIndex].name}
                </h4>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
