import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";

const ForgotPassword = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
    const [previousPassword, setPreviousPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const handleResetPassword = async () => {
        if (!(previousPassword.length > 5 && previousPassword.length < 100 && newPassword.length > 5 && newPassword.length < 100)) {
            toast.error("Password must be between 6 and 100 characters");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const response = await axios.put(`${BACKEND_URL}/api/v1/auth/reset-password`,
                { previousPassword, newPassword },
                {
                    headers: { Authorization: token }
                }
            );
            toast.success(response.data?.message || "Password reset successfully!");

            setPreviousPassword("");
            setNewPassword("");
        } catch (error) {
            toast.error(error.response?.data?.error || "Reset Password Failed");
            setPreviousPassword("");
            setNewPassword("");
        }
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="flex min-h-screen items-center justify-center bg-slate-950 p-4">
                <div className="w-full max-w-md bg-gradient-to-t from-slate-950 via-slate-800 to-purple-900 p-8 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold text-white text-center uppercase">Forgot Password</h2>
                    <p className="text-gray-400 text-center mt-2">Enter your email to reset your password.</p>
                    <div className="mt-6 flex flex-col gap-3">
                        <input
                            type="password"
                            className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                            placeholder="Previous Password"
                            value={previousPassword}
                            onChange={(e) => setPreviousPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>

                    <button
                        className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 text-lg rounded-full mt-4 transition duration-300 cursor-pointer"
                        onClick={handleResetPassword}
                    >
                        Reset Password
                    </button>

                    {/* <div className="mt-4 text-center">
                        <a href="/login" className="text-gray-400 no-underline cursor-pointer">Back to Login</a>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
