import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { BsCircleFill } from "react-icons/bs";

const Contact = () => {
  return (
    <>
    <section className="relative z-10 overflow-hidden  py-20 bg-gray-950 lg:py-[120px]">
      <div className="container mx-auto">
        <div className="flex flex-wrap lg:justify-between">
          <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
            <div className="mb-12 max-w-[570px] lg:mb-0">
              <span className="mb-4 block text-base font-semibold text-violet-600">Contact Us</span>
              <h2 className="mb-6 text-[32px] font-bold uppercase text-white sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                GET IN TOUCH WITH US
              </h2>
              <p className="mb-9 text-base leading-relaxed text-gray-300">
                We're here to help and answer any question you might have. We look forward to hearing from you!
              </p>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-violet-600 sm:h-[70px] sm:max-w-[70px]">
                  <FaMapMarkerAlt className="h-6 w-6" />
                </div>
                <div className="w-full">
                  <h4 className="mb-1 text-xl font-bold text-white">Our Location</h4>
                  <p className="text-base text-gray-300">
                  No 310, Kalyannagar, Outer Ring Rd, HRBR Layout 3rd Block, HRBR, HRBR Layout, Bengaluru, Karnataka 560043
                  </p>
                </div>
              </div>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-violet-600 sm:h-[70px] sm:max-w-[70px]">
                  <FaPhone className="h-6 w-6" />
                </div>
                <div className="w-full">
                  <h4 className="mb-1 text-xl font-bold text-white">Phone Number</h4>
                  <p className="text-base text-gray-300">+91 83106 50351
                  </p>
                </div>
              </div>
              <div className="mb-8 flex w-full max-w-[370px]">
                <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-violet-600 sm:h-[70px] sm:max-w-[70px]">
                  <FaEnvelope className="h-6 w-6" />
                </div>
                <div className="w-full">
                  <h4 className="mb-1 text-xl font-bold text-white">Email Address</h4>
                  <p className="text-base text-gray-300">education@juzzit.in</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
            <div className="relative rounded-lg  p-8 shadow-lg bg-gray-800 sm:p-12">
              <form>
                <ContactInputBox type="text" name="name" placeholder="Your Name" />
                <ContactInputBox type="email" name="email" placeholder="Your Email" />
                <ContactInputBox type="text" name="phone" placeholder="Your Phone" />
                <ContactTextArea row="6" placeholder="Your Message" name="details" defaultValue="" />
                <div>
                  <button
                    type="submit"
                    className="w-full rounded border border-violet-500 bg-violet-600 p-3 text-white transition hover:bg-opacity-90"
                  >
                    Send Message
                  </button>
                </div>
              </form>
              <div>
                <span className="absolute -right-10 top-[90px] z-[-1]">
                  <CircleGrid />
                </span>
                <span className="absolute -left-7 -bottom-7 z-[-1]">
                  <CircleGrid />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>

  )
}

export default Contact

const ContactTextArea = ({ row, placeholder, name, defaultValue }) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary border-dark-3 bg-gray-900 text-gray-300"
          defaultValue={defaultValue}
        />
      </div>
    </>
  )
}

const ContactInputBox = ({ type, placeholder, name }) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-violet-500 border-dark-3 bg-gray-900 text-gray-300"
        />
      </div>
    </>
  )
}

const CircleGrid = () => {
  return (
    <div className="grid grid-cols-3 gap-2">
      {[...Array(30)].map((_, index) => (
        <BsCircleFill key={index} className="text-[#a213c2] " size={8} />
      ))}
    </div>
  )
}

