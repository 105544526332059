import { useEffect, useState } from "react";
import banner from "../../assets/courses/course7.avif";
import about from "../../assets/detail_about.jpeg";
import offering1 from "../../assets/1.svg";
import offering2 from "../../assets/2.svg";
import offering3 from "../../assets/3.svg";
import offering4 from "../../assets/4.svg";
import offering5 from "../../assets/5.svg";
import offering6 from "../../assets/6.svg";
import offering7 from "../../assets/7.svg";
import offering8 from "../../assets/8.png";
import offering9 from "../../assets/9.svg";
import offering10 from "../../assets/10.svg";
import offering11 from "../../assets/11.svg";
import offering12 from "../../assets/12.svg";
import { FaChevronDown, FaChevronUp, FaBars } from "react-icons/fa";
import project1 from "../../assets/projects1.png";
import project2 from "../../assets/projects2.png";
import project3 from "../../assets/projects3.png";
import project4 from "../../assets/projects4.png";
import teacher1 from "../../assets/teacher.jpeg";
import linkedin from "../../assets/linkedin.svg";
import workExperience from "../../assets/workExperience.svg";
import teachingExperience from "../../assets/teachingExperience.svg";
import basic from "../../assets/basic.svg";
import premium from "../../assets/premium.svg";
import questionmark from "../../assets/questionmark.svg";
import certificate1 from "../../assets/certificate1.png";
import certificate2 from "../../assets/certificate2.png";
import counseller from "../../assets/counsellor.svg";
import quotes from "../../assets/quotes.svg";
import person from "../../assets/person.svg";
import confused from "../../assets/confused.svg";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Course7 = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const navigate = useNavigate();
  const location = useLocation();

  const handleBuyNow = async (courseId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
      return;
    }
    try {
      const response = await axios.get(`${BACKEND_URL}/api/v1/auth/me`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        navigate(`/enroll-success/${courseId}`); 
      }
    } catch (error) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

  }, []);
  const [selectedTab, setSelectedTab] = useState("Week1");
  const [openSections, setOpenSections] = useState({});
  const tabs = [
    "Week1",
    "Week2",
    "Week3",
    "Week4",
    "Week5",
    "Week6",
    "Week7",
    "Week8",

  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const curriculum = {
    Week1: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Introduction to Digital Marketing: What is digital marketing? Importance in the modern business environment.",
          "Key Digital Marketing Channels: SEO, PPC, Content Marketing, Social Media, Email Marketing, and Affiliate Marketing."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Understanding Digital Marketing Strategy: Setting goals, creating buyer personas, and defining KPIs.",
          "Introduction to the Sales Funnel: Awareness, Consideration, and Decision stages."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Overview of popular digital marketing tools (Google Analytics, SEMrush, Canva, Buffer).",
          "Setting up social media accounts for a brand and exploring Google Analytics basics."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Create a digital marketing strategy outline for a brand, including defined goals, target audience, and channel mix."
        ]
      }
    ],
    Week2: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Introduction to SEO: On-page SEO (meta tags, headings, URL structure, keyword optimization) vs. Off-page SEO (backlinks, social signals).",
          "Technical SEO Basics: Website structure, mobile optimization, page speed, sitemaps, and robots.txt."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Keyword Research: Tools like Google Keyword Planner, Ahrefs, SEMrush.",
          "Content Optimization: Writing SEO-friendly content and optimizing existing content for better rankings."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Perform a website audit using tools like Ahrefs or Google Search Console and identify SEO issues to address."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Conduct a keyword analysis for a website/blog and propose SEO improvements (content and technical SEO suggestions)."
        ]
      }
    ],
    Week3: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Content Marketing Strategy: Creating valuable content for the target audience, blog posts, videos, infographics, and podcasts.",
          "Writing Effective Copy: How to write for the web, persuasive writing, call-to-action (CTA), and storytelling."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Content Distribution: How to share and promote content through various channels (social media, newsletters, influencers, etc.).",
          "Tools for Content Creation: Canva for graphics, Grammarly for content writing, and tools for blog management (WordPress)."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Write a blog post and create social media content for a brand or product.",
          "Design graphics for the blog post using Canva and set up a simple content calendar."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Write 2 SEO-friendly blog posts on topics relevant to your target audience, complete with suggested social media posts."
        ]
      }
    ],
    Week4: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Overview of Social Media Platforms: Facebook, Instagram, Twitter, LinkedIn, YouTube, TikTok, etc.",
          "Social Media Strategy: Content creation, scheduling, engagement, and community building."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Social Media Ads: Introduction to Facebook Ads, Instagram Ads, LinkedIn Ads, and Google Ads.",
          "Analytics and Tracking: Understanding metrics like engagement, reach, impressions, click-through rate (CTR), and ROI."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Create a social media content plan for the next month (posts, themes, frequency) and set up a Facebook Ads campaign."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Run a small paid campaign on Facebook/Instagram, track the performance, and optimize based on the results."
        ]
      }
    ],
    Week5: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Introduction to PPC Advertising: Understanding the basics of Google Ads (search ads, display ads, remarketing).",
          "Keyword Bidding & Ad Auction: Understanding cost-per-click (CPC), ad rank, and quality score."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Setting up Google Ads Campaigns: Creating search, display, and video ads.",
          "Ad Copy Best Practices: Writing compelling and relevant ad copy."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Set up a Google Ads campaign (search or display), monitor performance, and optimize based on metrics."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Create a Google Ads campaign for a product or service and provide performance analysis with recommendations for improvements."
        ]
      }
    ],
    Week6: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Introduction to Email Marketing: Building an email list, segmentation, and personalizing email campaigns.",
          "Email Campaign Tools: Introduction to platforms like MailChimp, HubSpot, and ConvertKit."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Creating Engaging Newsletters: Tips for writing compelling subject lines, copy, and CTAs.",
          "Setting Up Email Automation: Creating email workflows and drip campaigns."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Design and send an email campaign using MailChimp or a similar platform.",
          "Analyze campaign performance and make improvements."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Design an email marketing campaign for a product launch or event, including list segmentation and automation."
        ]
      }
    ],
    Week7: [
      {
        "title": "Day 1-3",
        "lectures": [
          "Introduction to Affiliate Marketing: How affiliate marketing works, types of affiliates, and commission structures.",
          "Choosing the Right Affiliate Program: Factors to consider when selecting programs to join."
        ]
      },
      {
        "title": "Day 4-5",
        "lectures": [
          "Affiliate Marketing Strategies: Content-based promotion, paid advertising, and email marketing for affiliate products.",
          "Tracking Affiliate Performance: Using tools like Google Analytics and affiliate dashboards."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Set up an affiliate marketing campaign for a brand and track its performance using Google Analytics."
        ]
      }
    ],
    Week8: [
      {
        "title": "Day 1-2",
        "lectures": [
          "Understanding Analytics: Introduction to Google Analytics, setting up goals, tracking traffic, and conversions.",
          "Metrics to Track: Sessions, bounce rate, average session duration, and user demographics."
        ]
      },
      {
        "title": "Day 3-4",
        "lectures": [
          "Analyzing Social Media Metrics: Understanding engagement, reach, impressions, and conversion rates.",
          "Website Analytics Tools: Overview of alternative tools like Hotjar, Crazy Egg, and Kissmetrics."
        ]
      },
      {
        "title": "Day 5",
        "lectures": [
          "Practical Session: Review a website's analytics data and identify areas for improvement."
        ]
      },
      {
        "title": "End of Week Assignment",
        "lectures": [
          "Prepare a report on the digital marketing performance of a brand, including analysis of traffic, social media, and paid campaigns."
        ]
      }
    ]
  }


  const faqs = [
    {
      question: "What is this course about, and who is it for?",
      answer:
        "This course is a comprehensive journey through the foundational concepts, tools, and advanced techniques required for modern data science. It combines theory with practical implementation to help learners master Python, statistics, machine learning, deep learning, NLP, and Generative AI, preparing them for a wide range of data-driven roles. It’s ideal for beginners, freshers, or working professionals looking to build or switch careers in data science."
    },
    {
      question: "What are the prerequisites for enrolling in this course?",
      answer: "Basic knowledge of mathematics and programming concepts is recommended but not mandatory."
    },
    {
      question: "Is this course suitable for beginners?",
      answer: "Yes, this course is designed for beginners and professionals alike, with step-by-step guidance."
    },
    {
      question: "What topics and tools are covered in the course?",
      answer: "Topics include Python, statistics, machine learning, deep learning, NLP, and Generative AI, with tools like TensorFlow, PyTorch, and Pandas."
    },
    {
      question: "What is the duration of the course, and how much time should I dedicate weekly?",
      answer: "The course duration is approximately 3 months, with a recommended 6-8 hours per week for optimal learning."
    }
  ];

  const projects = [
    {
      title: "GeneAi - An Alexa Like Assistant",
      description:
        "A customized Alexa-like assistant with chat and voice command capabilities. Useful for day-to-day tasks like web searching, knowledge extraction from documents, or music recommendation.",
      image: project1, // Replace with actual image
    },
    {
      title: "Named Entity Recognition",
      description:
        "Utilizing Transformer models, NER project accurately identifies and extracts named entities from text.",
      image: project2, // Replace with actual image
    },
    {
      title: "On Prompt Image & Caption Generator",
      description:
        "Aiming at image generation and caption generation, this project helps the user to generate contents or topics and thumbnails with ease.",
      image: project3, // Replace with actual image
    },
    {
      title: "Customised Chat Bot",
      description:
        "Customized chatbot with LangChain and Chainlit to generate a Question Answering system or RAG system that extracts information from various sources of documents or simple web searching.",
      image: project4, // Replace with actual image
    },
  ];

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-black">

      <div className=" bg-black text-white">
        <div className="max-w-7xl p-6 mx-auto">

          {/* Top Banner */}
          <div className="flex md:flex-row flex-col justify-evenly items-center mb-8 md:p-4 rounded-3xl bg-gradient-to-t from-black via-slate-900 to-purple-800 text-white">
            <div className="w-full pr-16 p-4 flex flex-col justify-center md:items-start gap-4">
              <div className="md:text-4xl text-3xl font-bold">
                Digital Marketing
              </div>
              <div className="mt-2 text-lg">
                Digital marketing is the use of online platforms and technologies to promote products and services. It includes strategies like search engine optimization (SEO), social media marketing, email campaigns, and content creation. Digital marketing allows businesses to target a global audience, track performance metrics, and optimize campaigns in real-time, making it an essential tool for modern marketing success in a digital-first world.
              </div>
              <div className="text-base font-semibold">
                Enroll Now!
              </div>
              <div className="flex flex-wrap justify-center items-center gap-3">
                <div>
                  <button onClick={() => handleBuyNow(7)} className="bg-purple-800 hover:bg-purple-900 font-semibold px-16 py-2 rounded-lg text-nowrap w-60">Buy Now</button>
                </div>
                <div>
                  <button className="bg-purple-800 hover:bg-purple-900 font-semibold px-16 py-2 rounded-lg w-60">Share</button>
                </div>
              </div>
            </div>

            <div className="md:w-2/3 w-fit rounded-xl md:p-5 p-2 md:flex hidden">
              <img src={banner} alt="banner" className="rounded-xl border-black border w-fit" />
            </div>
          </div>

          {/* Key Highlights */}
          <div className="flex flex-wrap gap-3 justify-around mt-6 text-slate-300 text-sm">
            <div className="text-center">
              <p className="font-semibold">Job Assistance</p>
              <p>Program</p>
            </div>
            <div className="text-center">
              <p className="font-semibold">18th Feb 2025</p>
              <p>Start Date</p>
            </div>
            <div className="text-center">
              <p className="font-semibold">Self Paced</p>
              <p>Duration</p>
            </div>
            <div className="text-center">
              <p className="font-semibold">Recorded</p>
              <p>Delivery Mode</p>
            </div>
            <div className="text-center">
              <p className="font-semibold">Language</p>
              <p>English</p>
            </div>
          </div>

          {/* About This Course */}
          <div className="mt-20 flex justify-evenly items-center">
            <div className="md:w-2/3 md:p-2">
              <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">About This Course</h2>
              <h3 className="text-base text-wrap font-light">Discover your potential by learning the latest skills, using powerful tools, and gaining practical experience that can help you in the world of data science.</h3>
              <div className="flex flex-wrap gap-8">

                <div className="flex flex-col gap-2 mt-4">
                  <div className="flex gap-1">
                    <div>
                      ✅
                    </div>
                    <div className="font-bold text-lg">
                      Industry Professional Led Sessions
                    </div>
                  </div>
                  <div className="font-light">
                    Get guidance from qualified industry professionals.
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <div className="flex gap-1">
                    <div>
                      ✅
                    </div>
                    <div className="font-bold text-lg">
                      Industry Professional Led Sessions
                    </div>
                  </div>
                  <div className="font-light">
                    Get guidance from qualified industry professionals.
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <div className="flex gap-1">
                    <div>
                      ✅
                    </div>
                    <div className="font-bold text-lg">
                      Industry Professional Led Sessions
                    </div>
                  </div>
                  <div className="font-light">
                    Get guidance from qualified industry professionals.
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <div className="flex gap-1">
                    <div>
                      ✅
                    </div>
                    <div className="font-bold text-lg">
                      Industry Professional Led Sessions
                    </div>
                  </div>
                  <div className="font-light">
                    Get guidance from qualified industry professionals.
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <div className="flex gap-1">
                    <div>
                      ✅
                    </div>
                    <div className="font-bold text-lg">
                      Industry Professional Led Sessions
                    </div>
                  </div>
                  <div className="font-light">
                    Get guidance from qualified industry professionals.
                  </div>
                </div>

              </div>
            </div>
            <div className="w-1/3 md:flex hidden">
              <img src={about} alt="" className="rounded-2xl" />
            </div>
          </div>

          {/* Exclusive Course Offerings */}
          <div className="mt-24 flex flex-col justify-center items-start">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">Unlock Your Potential: Exclusive Course Offerings</h2>
            <div className="flex flex-wrap gap-4 pt-5 justify-center">

              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering1} alt="" className="w-12" />
                <div>Industry-Oriented Curriculum</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering2} alt="" className="w-12" />
                <div>Comprehensive Learning Content</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering3} alt="" className="w-12" />
                <div>Weekend Live Sessions</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering4} alt="" className="w-12" />
                <div>
                  Capstone Project</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering5} alt="" className="w-12" />
                <div>
                  Practice Exercises</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering6} alt="" className="w-12" />
                <div>
                  Assignments and Projects</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering7} alt="" className="w-12" />
                <div>Live Doubt Resolution Sessions</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering8} alt="" className="w-12" />
                <div>
                  Dedicated Buddy Support</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering9} alt="" className="w-12" />
                <div>Certification of Completion</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering10} alt="" className="w-12" />
                <div>Career Guidance & Interview Preparation</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering11} alt="" className="w-12" />
                <div>Email Support</div>
              </div>
              <div className="flex gap-3 justify-start items-center border p-2 rounded-lg md:w-72 w-full">
                <img src={offering12} alt="" className="w-12" />
                <div>
                  Peer to Peer Networking</div>
              </div>

            </div>
          </div>
          {/* Course Curriculum */}
          <div className="mt-24 pb-16">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">Course Curriculum</h2>
            <div className="flex flex-col items-center mt-4">
              {/* Mobile Dropdown (Visible on small screens) */}
              <div className="md:hidden w-full flex justify-center">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center px-5 py-2 bg-slate-500 text-white rounded-lg"
                >
                  <FaBars className="mr-2" />
                  Subjects
                </button>
              </div>

              {/* Dropdown Menu (Mobile View) */}
              {isDropdownOpen && (
                <div className="md:hidden mt-2 bg-slate-500 rounded-lg shadow-md w-48">
                  {tabs.map((tab) => (
                    <button
                      key={tab}
                      onClick={() => {
                        setSelectedTab(tab);
                        setIsDropdownOpen(false);
                      }}
                      className={`block w-full text-left px-5 py-2 text-sm font-medium rounded-lg ${selectedTab === tab ? "bg-purple-600 text-white" : "bg-slate-500 text-white hover:bg-slate-600"
                        }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              )}

              {/* Desktop View (Tabs in a row) */}
              <div className="hidden md:flex justify-center space-x-4 mt-4">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    className={`px-5 py-2 text-sm font-medium rounded-lg ${selectedTab === tab ? "bg-purple-600 text-white" : "bg-slate-500 text-white hover:bg-slate-600"
                      }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>
            <div className="mt-6">
              {curriculum[selectedTab] ? (
                curriculum[selectedTab].map((section, index) => (
                  <div key={index} className="bg-slate-400 rounded-lg mb-3">
                    <button
                      onClick={() => toggleSection(section.title)}
                      className="w-full flex justify-between items-center px-4 py-3 bg-slate-500 hover:bg-slate-600 rounded-lg"
                    >
                      <span className="font-semibold">{section.title}</span>
                      {openSections[section.title] ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                    {openSections[section.title] && (
                      <ul className="px-6 py-2 space-y-2">
                        {section.lectures.map((lecture, i) => (
                          <li key={i} className="bg-slate-500 p-3 hover:bg-slate-600 rounded-md shadow-sm">
                            {lecture}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center mt-4">No content available for {selectedTab}.</p>
              )}
            </div>
          </div>

          {/* Download Curriculum */}
          <div className="mt-6 text-center">
            <button className="bg-slate-900 text-purple-600 px-6 py-3 rounded-full border border-black">
              <div className="flex gap-2 justify-center items-center">
                <div className="font-bold">
                  Download Curriculum
                </div>
                <div className="border border-black p-2 rounded-full bg-purple-700">
                  <svg className="w-6 h-6 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 24 24">

                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                  </svg>
                </div>
              </div>
            </button>
          </div >

          {/* real world projects */}
          <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">
              Real-World Projects: Apply What You Learn
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {projects.map((project, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-t from-black via-slate-900 to-purple-700 rounded-xl shadow-md p-5 flex flex-wrap items-center gap-4"
                >
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                  <div>
                    <h3 className="font-semibold text-lg">{project.title}</h3>
                    <p className="text-slate-400 text-sm">{project.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Esteemed faculties */}
          {/* <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">
              Guidance By Professionals: Our Esteemed Faculties
            </h2>
            <div className="font-light">Experience excellence in mentorship from industry-leading professional.</div>

            <div className="max-w-7xl mx-auto py-10 px-4 mt-14 flex flex-wrap rounded-2xl gap-5 justify-center items-center">

              <div className="w-72 flex flex-col gap-1 rounded-xl bg-gradient-to-t from-black via-slate-900 to-purple-700">
                <div><img src={teacher1} alt="" className="rounded-t-xl" /></div>
                <div className="p-2 flex flex-col gap-2">
                  <div className="font-bold text-lg">Dr. John Doe</div>
                  <div className="text-slate-400 text-sm">Software Engineer</div>
                  <div><img src={linkedin} className="cursor-pointer" onClick={() => window.open("https://www.linkedin.com/", "_blank")} alt="" /></div>
                  <div>Deloitte | Capgemini</div>
                  <div className="flex justify-between">
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={workExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          5+ Years
                        </div>
                        <div className="font-light text-xs">
                          Work Experience
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={teachingExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          3+ Years
                        </div>
                        <div className="font-light text-xs">
                          Teaching Experience
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="font-light text-sm p-1">
                    Data Scientist - Fractel | Capgemini, Deloitte | Business Insigts | ML & Analisys Specialist
                  </div>
                </div>
              </div>
              <div className="w-72 flex flex-col gap-1 rounded-xl bg-gradient-to-t from-black via-slate-900 to-purple-700">
                <div><img src={teacher1} alt="" className="rounded-t-xl" /></div>
                <div className="p-2 flex flex-col gap-2">
                  <div className="font-bold text-lg">Dr. John Doe</div>
                  <div className="text-slate-400 text-sm">Software Engineer</div>
                  <div><img src={linkedin} className="cursor-pointer" onClick={() => window.open("https://www.linkedin.com/", "_blank")} alt="" /></div>
                  <div>Deloitte | Capgemini</div>
                  <div className="flex justify-between">
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={workExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          5+ Years
                        </div>
                        <div className="font-light text-xs">
                          Work Experience
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={teachingExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          3+ Years
                        </div>
                        <div className="font-light text-xs">
                          Teaching Experience
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="font-light text-sm p-1">
                    Data Scientist - Fractel | Capgemini, Deloitte | Business Insigts | ML & Analisys Specialist
                  </div>
                </div>
              </div>
              <div className="w-72 flex flex-col gap-1 rounded-xl bg-gradient-to-t from-black via-slate-900 to-purple-700">
                <div><img src={teacher1} alt="" className="rounded-t-xl" /></div>
                <div className="p-2 flex flex-col gap-2">
                  <div className="font-bold text-lg">Dr. John Doe</div>
                  <div className="text-slate-400 text-sm">Software Engineer</div>
                  <div><img src={linkedin} className="cursor-pointer" onClick={() => window.open("https://www.linkedin.com/", "_blank")} alt="" /></div>
                  <div>Deloitte | Capgemini</div>
                  <div className="flex justify-between">
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={workExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          5+ Years
                        </div>
                        <div className="font-light text-xs">
                          Work Experience
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <div>
                        <img src={teachingExperience} className="w-5" alt="" />
                      </div>
                      <div className="p-1">
                        <div>
                          3+ Years
                        </div>
                        <div className="font-light text-xs">
                          Teaching Experience
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="font-light text-sm p-1">
                    Data Scientist - Fractel | Capgemini, Deloitte | Business Insigts | ML & Analisys Specialist
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* course plan */}
          <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">
              Customize Your Growth: Course Plan Options
            </h2>
            {/* cards for plans */}
            <div className="gap-6 w-full flex justify-center flex-wrap pt-20 text-black">
              {/* Basic Plan */}
              <div className="bg-white p-6 rounded-lg shadow-md border flex flex-col justify-between">
                <div>
                  <div className="flex flex-col items-start gap-2">
                    {/* <span className="text-blue-500 text-xl"><img src={basic} alt="" /></span> */}
                    {/* <h3 className="text-xl font-semibold">Basic</h3> */}
                  </div>
                  <p className="text-2xl font-bold mt-2">₹ 4999 + GST <span className="line-through text-gray-400">10000</span></p>
                  <p className="text-gray-600 mt-1">Enroll Now!</p>
                  <button className="mt-4 w-full bg-red-500 text-white font-semibold py-2 rounded hover:bg-red-600">
                    Buy now
                  </button>
                  <h4 className="mt-4 font-semibold">Recorded Lectures</h4>
                  <p className="text-gray-600 text-sm">Learn on your own pace through recorded lectures.</p>
                </div>

                <div className="mt-4 p-3 bg-gray-100 rounded text-center text-sm text-gray-600">
                  Kickstart your upskilling journey with our Basic Plan – The Beginner’s Choice!
                </div>
              </div>
              {/* <span className="bg-purple-200 text-purple-600 text-xs font-semibold px-2 py-1 rounded-full">Recommended</span> */}
              {/* Premium Plan */}
              {/* <div className="bg-white p-6 rounded-lg shadow-md border border-red-400 relative">
                <div className="flex items-end gap-2 ">

                  <div className="flex flex-col items-start gap-2">
                    <span className="text-blue-500 text-xl"><img src={premium} alt="" /></span>
                    <div className="flex gap-2">
                      <h3 className="text-xl font-semibold">Premium</h3>
                      <div className="bg-purple-500 text-white text-xs font-semibold px-4  rounded-full flex justify-center items-center">Recommended</div>
                    </div>
                  </div>

                </div>
                <p className="text-2xl font-bold mt-2">₹ 15999 <span className="line-through text-gray-400">20000</span></p>
                <button className="mt-4 w-full bg-red-500 text-white font-semibold py-2 rounded hover:bg-red-600">
                  Buy now
                </button>
                <h3 className="mt-4 font-semibold text-lg">Recorded Lectures</h3>
                <p className="text-gray-600 text-sm">Get recorded lectures on a weekly basis.</p>
                <h3 className="mt-2 font-semibold text-lg">Weekly Revision Sessions</h3>
                <p className="text-gray-600 text-sm">Weekly sessions to revisit recorded lectures.</p>
                <h3 className="mt-2 font-semibold text-lg">Daily Doubt Sessions</h3>
                <p className="text-gray-600 text-sm">Mon to Fri Doubt sessions for clearing doubts and reinforcing concepts.</p>
                <h3 className="mt-2 font-semibold text-lg">Interview Preparation</h3>
                <p className="text-gray-600 text-sm">Get Sessions like LinkedIn profile building, Resume preparation.</p>
                <div className="mt-4 p-3 bg-yellow-100 rounded text-center text-sm text-gray-600">
                  Get End to End Placement Assistance With Our Plan
                </div>
              </div> */}
            </div>

          </div>


        </div>

        {/* confused? */}
        <div className="w-full flex justify-center items-center bg-purple-200 text-black font-bold gap-3 p-1">
          <div className="flex justify-center items-center">
            <img src={questionmark} alt="question mark" className="p-2" />
            Still confused? Let us clear all  your queries
          </div>
          <button onClick={() => window.location.href = 'tel:+918310650351'} className="bg-purple-600 text-white px-3 md:py-1.5 text-sm h-fit  rounded">Get Call Back</button>
        </div>

        {/* certificates */}
        <div className="max-w-7xl p-6 mx-auto">
          <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">
              Validating Your Success: About Your Certificate
            </h2>
            <div className="flex flex-wrap justify-center py-10 gap-8">
              <div>
                <img src={certificate1} alt="certificate1" className="h-96" />
                <label className="text-sm" htmlFor="certificate1">Internship Completion Certificate</label>
              </div>
              <div>
                <img src={certificate2} alt="certificate2" className="h-96" />
                <label className="text-sm" htmlFor="certificate2">Certificate of Achievement</label>

              </div>
            </div>


          </div>
        </div>
        {/* counsellor */}
        <div className="w-full flex flex-wrap justify-evenly items-center bg-purple-200 text-black md:px-32 p-4 gap-2">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-3xl">
                Talk to Our Counsellor
              </div>
              <div className="flex md:gap-2">
                Get Expert Advice our Counsellor will reach within 24 hour.
              </div>
            </div>
            <div>
              <button onClick={() => window.location.href = 'mailto:education@juzzit.in'} className="rounded-lg bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 w-fit">Get Connected Now</button>
            </div>
          </div>
          <div>
            <img src={counseller} alt="counseller" />
          </div>
        </div>

        <div className="max-w-7xl md:p-6 mx-auto">
          <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">
              Champions of Change: Alumni Experiences
            </h2>
            <h3 className="text-base text-wrap font-light">Unlock the potential within our alumni's experiences and witness the transformative power of upscaling on their careers and lives.</h3>
            <div className="flex flex-wrap justify-center py-10 gap-8">
              {/* card */}
              <div className="flex flex-col gap-2 w-72 border p-3 rounded-2xl">
                <img src={quotes} alt="questes" className="w-10" />
                <div>
                  Thank you for the entire team who have been guiding me from the beginning to this stage. Thank you everyone
                </div>
                <hr />
                <div className="flex gap-3 justify-start items-center">
                  <div>
                    <img src="/images/Akhila Reddy.png" alt="" className="rounded-full w-10" />
                  </div>
                  <div>
                    <div className="font-semibold">
                      Akhila Reddy
                    </div>
                    <div className="font-light">
                      Business Analyst
                    </div>
                  </div>
                </div>
              </div>
              {/* card */}
              <div className="flex flex-col gap-2 w-72 border p-3 rounded-2xl">
                <img src={quotes} alt="questes" className="w-10" />
                <div>
                  I highly recommend Juzz It for anyone looking to upskill in tech. The platform is easy to use, and the support is excellent!
                </div>
                <hr />
                <div className="flex gap-3 justify-start items-center">
                  <div>
                    <img src="/images/Aqib Mohammed.png" alt="" className="rounded-full w-10" />
                  </div>
                  <div>
                    <div className="font-semibold">
                      Aqib Mohammed
                    </div>
                    <div className="font-light">
                      Business Analyst
                    </div>
                  </div>
                </div>
              </div>
              {/* card */}
              <div className="flex flex-col gap-2 w-72 border p-3 rounded-2xl">
                <img src={quotes} alt="questes" className="w-10" />
                <div>
                  Juzz It's courses helped me land my dream job. The practical projects and certifications are extremely valuable.
                </div>
                <hr />
                <div className="flex gap-3 justify-start items-center">
                  <div>
                    <img src="/images/Kiran Swami.png" alt="" className="rounded-full w-10" />
                  </div>
                  <div>
                    <div className="font-semibold">
                      Kiran Swami
                    </div>
                    <div className="font-light">
                      Business Analyst
                    </div>
                  </div>
                </div>
              </div>
              {/* card */}
              <div className="flex flex-col gap-2 w-72 border p-3 rounded-2xl">
                <img src={quotes} alt="questes" className="w-10" />
                <div>
                  The instructors are knowledgeable and the community support is outstanding. I've learned so much in just a few months.
                </div>
                <hr />
                <div className="flex gap-3 justify-start items-center">
                  <div>
                    <img src="/images/Nagaraj K.png" alt="" className="rounded-full w-10" />
                  </div>
                  <div>
                    <div className="font-semibold">
                      Nagaraj K
                    </div>
                    <div className="font-light">
                      Business Analyst
                    </div>
                  </div>
                </div>
              </div>
              {/* card */}
              <div className="flex flex-col gap-2 w-72 border p-3 rounded-2xl">
                <img src={quotes} alt="questes" className="w-10" />
                <div>
                  What sets Juzz It apart is their hands-on approach to learning. The real-world projects really helped cement my understanding.
                </div>
                <hr />
                <div className="flex gap-3 justify-start items-center">
                  <div>
                    <img src="/images/Sunil B.png" alt="" className="rounded-full w-10" />
                  </div>
                  <div>
                    <div className="font-semibold">
                      Sunil B
                    </div>
                    <div className="font-light">
                      Business Analyst
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>

          {/* <div className="max-w-7xl mx-auto py-10 px-4 mt-14">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-orange-500 pl-4">

              Data Science Course
            </h2>
            <div className="md:p-3 p-1">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil ab quasi architecto nemo assumenda illum, neque fugiat enim ipsa minima vero, asperiores labore saepe? Sit id exercitationem sapiente consectetur, pariatur iure iusto animi dolorem ad obcaecati laudantium harum omnis deleniti ipsum voluptatibus molestiae ex et aspernatur quisquam corporis itaque? Aliquid hic sequi facilis dolore unde harum? Totam mollitia adipisci libero assumenda facilis dolor et obcaecati accusamus nam, porro unde sequi, pariatur excepturi ullam voluptatem cupiditate aliquid in ut ad. Reiciendis eveniet itaque hic sit laudantium! Soluta et quam maxime consequatur alias nobis dolores earum, recusandae eligendi reiciendis odit ipsum ex, veniam delectus doloribus rerum accusantium voluptatibus consequuntur quibusdam possimus qui voluptate architecto. Nemo ullam voluptatem error sapiente iure, nisi, sed quod commodi maxime dolorem doloremque modi accusantium ea autem cumque? Fugit architecto odio aut voluptates, excepturi modi aliquam quibusdam, ratione dolor, corrupti dolores. Ad laboriosam labore adipisci voluptatibus, placeat culpa nihil delectus vel exercitationem. Quae quasi iure, unde omnis consequuntur quos, ipsum repellat neque eveniet ipsam non dolor, numquam provident. Molestias dolorum esse, ipsam aliquid ad eveniet consequatur nostrum, in, reprehenderit excepturi nihil. Sunt assumenda labore ut dolorem unde! Sit tempore voluptatibus minima optio assumenda consequuntur reiciendis atque soluta quaerat.
            </div>


          </div> */}

        </div>
        {/* confused? */}
        <div className="w-full flex flex-wrap justify-evenly items-center bg-purple-200 text-black md:px-32 p-3 gap-2">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-3xl">
                Still Confused?
              </div>
              <div className="flex gap-2">
                Get Connected to our experts and know what's best for you. Achieve your dreams!
              </div>
            </div>
            <div>
              <button onClick={() => window.location.href = 'mailto:education@juzzit.in'} className="rounded-lg bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 w-fit">Get Connected Now</button>
            </div>
          </div>
          <div>
            <img src={confused} alt="counseller" />
          </div>
        </div>

        <div className="max-w-3xl mx-auto p-6">
          <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="border-b border-purple-300 pb-2 cursor-pointer"
              >
                <div
                  className={`flex justify-between items-center p-3 hover:bg-purple-100 rounded-lg group`}
                  onClick={() => toggleFAQ(index)}
                >
                  <p
                    className={`font-semibold text-lg group-hover:text-black ${activeIndex === index ? "text-purple-500" : "text-purple-300"
                      }`}
                  >
                    {faq.question}
                  </p>
                  <FaChevronRight
                    className={`transition-transform transform ${activeIndex === index ? "rotate-90" : "rotate-0"
                      } group-hover:text-black`}
                  />
                </div>
                {activeIndex === index && (
                  <p className="p-3 text-white">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>


      </div >
    </div>
  );
};

export default Course7;
