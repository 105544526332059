"use client";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { courses } from "../components/coursesData"; 

function BuyNow() {
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const { courseId } = useParams();
  const navigate = useNavigate();

  console.log("Course ID from URL:", courseId);

  const course = courses.find((c) => String(c.id) === String(courseId));


  console.log("Found Course:", course);

  if (!course) {
    console.error("Error: Course not found for ID", courseId);
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <p className="text-red-500 text-xl font-semibold">Course not found!</p>
      </div>
    );
  }

  const handleApplyCoupon = async () => {
    try {
      console.log("Applying Coupon Code:", couponCode);

      if (couponCode === "DISCOUNT50") {
        const discountAmount = course.originalPrice * 0.5;
        setDiscount(discountAmount);
        console.log("Discount Applied:", discountAmount);
      } else {
        setDiscount(0);
        console.warn("Invalid coupon code entered:", couponCode);
        alert("Invalid coupon code.");
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  const handleProceedToCheckout = () => {
    console.log("Proceeding to checkout for Course ID:", courseId);
    navigate(`/checkout/${courseId}`);
  };

  const subtotal = course.originalPrice;
  console.log("Original price:", subtotal);
  const total = subtotal - discount;

  console.log("Subtotal:", subtotal);
  console.log("Discount:", discount);
  console.log("Total Price after Discount:", total);

  return (
    <div className="min-h-screen bg-[#171b22] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-[#1B2028] text-white !border border-[#30363d] shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <h2 className="text-2xl font-bold text-center mb-6">Details</h2>
          <hr class="h-px my-8 bg-gray-200 border-0 "/>
          {/* Course Preview */}
          <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
            <img
              src={course.image || "/placeholder.svg"}
              alt={course.title}
              className="h-24 w-40 object-cover rounded-lg"
            />
            <div>
              <h3 className="font-medium">{course.title}</h3>
              <p className="text-sm text-gray-400">{course.description}</p>
            </div>
          </div>

          {/* Coupon Section */}
          <div className="mb-6">
            <p className="text-sm text-gray-400 mb-2">Have a discount coupon?</p>
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="DISCOUNT50"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                className="flex-1 px-3 py-2 bg-gray-950 !border border-gray-600 rounded-md"
              />
              <button
                onClick={handleApplyCoupon}
                className="px-4 py-2 bg-violet-600 text-white rounded-md hover:bg-violet-700"
              >
                Apply
              </button>
            </div>
          </div>

          {/* Price Breakdown */}
          <div className="space-y-3 pt-4 border-t border-gray-500">
            <div className="flex justify-between">
              <span className="text-gray-600">Subtotal</span>
              <span>₹ {subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-green-600">
              <span>Discount</span>
              <span>- ₹ {discount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold pt-2 border-t border-gray-500">
              <span>Total</span>
              <span>₹ {total.toFixed(2)}</span>
            </div>
          </div>
        </div>

        {/* Checkout Button */}
        <div className="px-6 py-4 bg-gray-900">
          <button
            onClick={handleProceedToCheckout}
            className="w-full py-2 px-4 bg-violet-600 hover:bg-blue-600 text-white rounded-md transition-colors"
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </div>
  );
}

export default BuyNow;
