import { FiSearch, FiFilter } from "react-icons/fi"
import { BsStarFill } from "react-icons/bs"
import Courses from "../components/Course"

const DiscoverPage = () => {


  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header */}
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-2">Discover Courses</h1>
        <p className="text-gray-400 mb-8">
          Explore our range of courses in AI, Cloud computing, Data Science, and medical technology
        </p>

        {/* Search and Filter */}
        <div className="flex flex-wrap gap-4 mb-8">
          <div className="flex-1 min-w-[300px] relative">
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search courses..."
              className="w-full pl-10 pr-4 py-2 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <button className="flex items-center gap-2 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700">
            <FiFilter />
            Filter
          </button>
        </div>

        <Courses/>

      </div>
    </div>
  )
}

export default DiscoverPage

