import brand1 from "../assets/brand/google.svg";
import brand2 from "../assets/brand/microsoft.svg";
import brand3 from "../assets/brand/redhat.svg";
import brand4 from "../assets/brand/iso.svg";
import brand5 from "../assets/brand/tsn.svg";

const Brand = () => {
    const brands = [brand1, brand2, brand3, brand4, brand5];

    return (
        <section className="bg-black py-16 pb-20 px-6 flex justify-center">
            <div className="bg-gradient-to-t from-black via-slate-900 to-purple-800 rounded-2xl p-10 max-w-7xl text-center w-full">
                <h2 className="text-3xl md:text-4xl font-bold text-slate-300">
                    Trusted by 25,000+ world-class <br /> brands and organizations of all sizes
                </h2>
                <p className="text-gray-400 mt-4">
                    Leading companies and organizations across industries rely on our technology to drive innovation, efficiency, and success. Join a growing community of brands that trust our expertise.
                </p>

                <div className="flex flex-wrap justify-center items-center gap-28 mt-11 border-t border-gray-700 pt-6">
                    {brands.map((brand, index) => (
                        <img key={index} src={brand} alt={`brand ${index + 1}`} className="h-12 md:h-16 opacity-70" />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Brand;
