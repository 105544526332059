import React from "react";

function NeedHelp() {
  return (
    <div className="relative bg-gradient-to-r from-[#1e1f26] to-[#211527] text-white rounded-lg p-8 shadow-lg flex flex-col items-center justify-center w-[95%] max-w-screen-xl mx-auto mb-8">
      {/* Background Box */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-500 opacity-20 rounded-lg -z-10"></div>

      {/* Avatar Group */}
      <div className="flex space-x-4 mb-6">
        <img
          src="./images/Need_help_images/need_help_1.avif"
          alt="Avatar 1"
          className="w-14 h-14 rounded-full border-2 border-white"
        />
        <img
          src="./images/Need_help_images/need_help_2.jpg"
          alt="Avatar 2"
          className="w-14 h-14 rounded-full border-2 border-white"
        />
        <img
          src="./images/Need_help_images/need_help_3.avif"
          alt="Avatar 3"
          className="w-14 h-14 rounded-full border-2 border-white"
        />
        <img
          src="./images/Need_help_images/need_help_4.avif"
          alt="Avatar 4"
          className="w-14 h-14 rounded-full border-2 border-white"
        />
      </div>

      {/* Text Section */}
      <div className="text-center mb-6">
        <h3 className="text-2xl font-semibold mb-3">Need help?</h3>
        <p className="text-base text-gray-300">
        If you have questions or need support, our team is here to assist you every step of the way.
        </p>
      </div>

      {/* Button */}
      <a
        href="/contact-us"
        className="bg-purple-500 text-white px-8 py-3 rounded-full shadow-md hover:bg-purple-600 transition"
      >
        Contact us
      </a>
    </div>
  );
}

export default NeedHelp;
