// src/data/coursesData.js

import course1 from "../assets/courses/course1.jpg";
import course2 from "../assets/courses/course2.jpg";
import course3 from "../assets/courses/course3.jpg";
import course4 from "../assets/courses/course4.png";
import course5 from "../assets/courses/course5.avif";
import course6 from "../assets/courses/course6.jpeg";
import course7 from "../assets/courses/course7.avif";
import course8 from "../assets/courses/course8.webp";
import course9 from "../assets/courses/course9.jpg";
import course10 from "../assets/courses/course10.png";
import course11 from "../assets/courses/course11.png";
import course12 from "../assets/courses/course12.png";
import course13 from "../assets/courses/course13.jpg";
import course14 from "../assets/courses/course14.jpg";
import course15 from "../assets/courses/course15.png";
import course16 from "../assets/courses/course16.png";

export   const courses = [
    {
      id: 1,
      title: "Full Stack Development",
      description: "Master both front-end and back-end technologies to become a versatile developer.",
      image: course1,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 2,
      title: "Data Science",
      description: "Learn to analyze and interpret complex data to drive business decisions.",
      image: course2,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 3,
      title: "Business Analysis",
      description: "Develop skills to bridge the gap between IT and business using data-driven techniques.",
      image: course3,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 4,
      title: "Hybrid Electric Vehicle Design and Analysis",
      description: "Explore the cutting-edge technology behind hybrid electric vehicles.",
      image: course4,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 5,
      title: "Electric Vehicle Design and Analysis",
      description: "Learn about the design principles and analysis techniques for electric vehicles.",
      image: course5,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 6,
      title: "Cybersecurity",
      description: "Protect digital assets and networks from cyber threats and attacks.",
      image: course6,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 7,
      title: "Digital Marketing",
      description: "Master the art of promoting brands and products in the digital landscape.",
      image: course7,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 8,
      title: "HR Management",
      description: "Develop skills to effectively manage human resources in modern organizations.",
      image: course8,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 9,
      title: "AI & ML",
      description: "Dive into the world of Artificial Intelligence and Machine Learning.",
      image: course9,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 10,
      title: "Generative AI",
      description: "Explore the latest advancements in AI that can create new content.",
      image: course10,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 11,
      title: "Cloud Computing and DevOps",
      description: "Learn to build, deploy, and manage applications in the cloud using DevOps practices.",
      image: course11,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 12,
      title: "Accounts & Finance",
      description: "Gain expertise in financial management and accounting principles.",
      image: course12,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 13,
      title: "Medical Technology",
      description: "Explore the intersection of healthcare and technology in this cutting-edge field.",
      image: course13,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 14,
      title: "High-Rise Building Design & Analysis",
      description: "Learn the principles of designing and analyzing tall structures and skyscrapers.",
      image: course14,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 15,
      title: "MERN Stack",
      description: "Master MongoDB, Express.js, React, and Node.js for full-stack web development.",
      image: course15,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
    {
      id: 16,
      title: "DSA & System Design",
      description:
        "Learn Data Structures, Algorithms, and System Design principles for efficient software development.",
      image: course16,
      originalPrice: 10000,
      discountedPrice: "4999",
    },
  ]
