import { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";

const SignUp = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
    const [studentFormData, setStudentFormData] = useState({
        fullName: "",
        email: "",
        username: "",
        password: "",
        number: "",
        interests: "",
    });
    const [teacherFormData, setTeacherFormData] = useState({
        fullName: "",
        email: "",
        username: "",
        password: "",
        interests: "",
    });
    const [step, setstep] = useState(1);
    const [selectedRole, setselectedRole] = useState("");

    const navigate = useNavigate();
    const location = useLocation();
    // Extract the "redirect" query parameter from the URL if it exists.
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get("redirect");

    useEffect(() => {
        window.scroll(0, 0);

    }, []);

    const handleNext = () => {
        if (step === 1) {
            if (selectedRole === "student") {
                setstep(2);
            } else if (selectedRole === "teacher") {
                setstep(3);
            }
        }
    };

    const handleChange = (e) => {
        if (selectedRole === "student") {
            setStudentFormData({ ...studentFormData, [e.target.name]: e.target.value });
        } else {
            setTeacherFormData({ ...teacherFormData, [e.target.name]: e.target.value });
        }
    };

    const handleStudentSubmit = async (e) => {
        e.preventDefault();
        if (
            !studentFormData.fullName ||
            !studentFormData.email ||
            !studentFormData.password ||
            !studentFormData.username ||
            !studentFormData.number ||
            !studentFormData.interests
        ) {
            toast.error("Please fill all the fields");
            return;
        }
        try {
            const response = await axios.post(`${BACKEND_URL}/api/v1/auth/signup`, {
                ...studentFormData,
                role: "student",
            });
            toast.success(response.data.message || "Sign-up successful!");
            // Redirect to the login page. Preserve the redirect query parameter if present.
            navigate(
                redirectUrl
                    ? `/login?redirect=${encodeURIComponent(redirectUrl)}`
                    : "/login"
            );
        } catch (error) {
            console.error(error.response?.data?.error || "Something went wrong");
            toast.error(error.response?.data?.error || "Signup failed");
        }
    };

    const handleTeacherSubmit = async (e) => {
        e.preventDefault();
        if (
            !teacherFormData.fullName ||
            !teacherFormData.email ||
            !teacherFormData.password ||
            !teacherFormData.username ||
            !teacherFormData.interests
        ) {
            toast.error("Please fill all the fields");
            return;
        }
        try {
            const response = await axios.post(`${BACKEND_URL}/api/v1/auth/signup`, {
                ...teacherFormData,
                role: "teacher",
            });
            toast.success(response.data.message || "Sign-up successful!");
            // Redirect to the login page. Preserve the redirect query parameter if present.
            navigate(
                redirectUrl
                    ? `/login?redirect=${encodeURIComponent(redirectUrl)}`
                    : "/login"
            );
        } catch (error) {
            console.error(error.response?.data?.error || "Something went wrong");
            toast.error(error.response?.data?.error || "Signup failed");
        }
    };

    const handleGoogleSignup = () => {
        // Google sign-up logic here
        toast.success("Sign-up successful with Google!");
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="flex justify-center items-center min-h-screen bg-slate-950 p-4 text-white">
                <div className="bg-gradient-to-t from-slate-950 via-slate-800 to-purple-900 p-8 rounded-3xl shadow-lg w-full max-w-md">
                    {step === 1 && (
                        <h2 className="text-3xl font-bold text-white mb-6 text-center uppercase pb-2">
                            Sign Up
                        </h2>
                    )}
                    {(step === 2 || step === 3) && (
                        <h2 className="text-3xl font-bold text-white text-center uppercase">
                            Sign Up
                        </h2>
                    )}
                    {step === 2 && (
                        <div className="font-light text-md text-center mb-6">
                            (For Student)
                        </div>
                    )}
                    {step === 3 && (
                        <div className="font-light text-md text-center mb-6">
                            (For Teacher)
                        </div>
                    )}
                    {step === 1 && (
                        <>
                            <div className="flex flex-col items-center space-y-4">
                                <div className="flex gap-4">
                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <input
                                            type="radio"
                                            name="role"
                                            value="student"
                                            checked={selectedRole === "student"}
                                            onChange={() => {
                                                setselectedRole("student");
                                            }}
                                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <span className="text-lg font-medium">Student</span>
                                    </label>

                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <input
                                            type="radio"
                                            name="role"
                                            value="teacher"
                                            checked={selectedRole === "teacher"}
                                            onChange={() => {
                                                setselectedRole("teacher");
                                            }}
                                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <span className="text-lg font-medium">Teacher</span>
                                    </label>
                                </div>
                                <button
                                    onClick={handleNext}
                                    className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}

                    {step === 2 && (
                        <form onSubmit={handleStudentSubmit} className="space-y-4">
                            <input
                                type="text"
                                name="fullName"
                                placeholder="Registration Name"
                                value={studentFormData.fullName}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Registration Email"
                                value={studentFormData.email}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={studentFormData.username}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={studentFormData.password}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="number"
                                name="number"
                                placeholder="Mobile Number"
                                value={studentFormData.number}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="text"
                                name="interests"
                                placeholder="Interested Categories"
                                value={studentFormData.interests}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <button
                                type="submit"
                                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                            >
                                Sign Up
                            </button>
                        </form>
                    )}

                    {step === 3 && (
                        <form onSubmit={handleTeacherSubmit} className="space-y-4">
                            <input
                                type="text"
                                name="fullName"
                                placeholder="Full Name"
                                value={teacherFormData.fullName}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={teacherFormData.email}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={teacherFormData.username}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={teacherFormData.password}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <input
                                type="text"
                                name="interests"
                                placeholder="Interest (Eg: Javascript, Python, etc.)"
                                value={teacherFormData.interests}
                                onChange={handleChange}
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                required
                            />
                            <button
                                type="submit"
                                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                            >
                                Sign Up
                            </button>
                        </form>
                    )}

                    <div className="text-center my-4 text-gray-400">or</div>
                    <button
                        onClick={handleGoogleSignup}
                        className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                    >
                        <FaGoogle /> Sign up with Google
                    </button>
                    <p className="text-gray-400 text-center mt-4">
                        Already have an account?{" "}
                        <Link to="/login" className="text-purple-400 cursor-pointer no-underline">
                            Login
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};

export default SignUp;
