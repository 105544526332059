import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import ForgotPassword from './components/auth/ForgotPassword';
import TermsAndConditionPage from './components/TermsAndConditionPage';
import ContactUs from './Pages/ContactUs';
import CourseDetail from './components/CourseDetail';
import Navbar from './components/Navbar';
import AboutUs from './Pages/AboutUs';
import Course1 from './components/courses/Course1';
import Course2 from './components/courses/Course2';
import Course3 from './components/courses/Course3';
import Course4 from './components/courses/Course4';
import Course5 from './components/courses/Course5';
import Course6 from './components/courses/Course6';
import Course7 from './components/courses/Course7';
import Course8 from './components/courses/Course8';
import Course9 from './components/courses/Course9';
import Course10 from './components/courses/Course10';
import Course11 from './components/courses/Course11';
import Course12 from './components/courses/Course12';
import Course13 from './components/courses/Course13';
import Course14 from './components/courses/Course14';
import Course15 from './components/courses/Course15';
import Course16 from './components/courses/Course16';
import FAQ from './Pages/FAQ';
import Blogs from './Pages/Blogs';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import NotFound from './Pages/NotFound';
import RouteTitleUpdater from './RouteTitleUpdater';
import DiscoverPage from './Pages/DiscoverPage';
import StudentDashboard from "./components/studentdashboard";
import TeacherDashboard from "./components/teacherdashboard";
import EnrolledCourses from './components/EnrolledCourses';
import ProfileSettings from './Pages/ProfileSetting';
import StudentProtectedRoutes from './StudentProtectedRoutes';
import TeacherProtectedRoutes from './TeacherProtectedRoutes';
import SemiPublicRoutes from './SemiPublicRoutes';
import BuyNow from './Pages/BuyNow';


const router = createBrowserRouter([
  {
    element: <StudentProtectedRoutes />,
    children: [
      {
        path: "/student-dashboard",
        element: <><Navbar /><RouteTitleUpdater /><StudentDashboard /><Footer /></>
      },
      {
        path: "/enrolled-courses",
        element: <><Navbar /><RouteTitleUpdater /><EnrolledCourses /><Footer /></>
      },
    ]
  },
  {
    element: <TeacherProtectedRoutes />,
    children: [
      {
        path: "/teacher-dashboard",
        element: <><Navbar /><RouteTitleUpdater /><TeacherDashboard /><Footer /></>
      },
    ]
  },
  {
    element: <SemiPublicRoutes />,
    children: [
      {
        path: "/login",
        element: <><Navbar /><RouteTitleUpdater /><Login /><Footer /></>
      },
      {
        path: "/signup",
        element: <><Navbar /><RouteTitleUpdater /><SignUp /><Footer /></>
      },
    ]
  },
  {
    path: "/",
    element: <><Navbar /><RouteTitleUpdater /><App /><Footer /></>,
  },
  {
    path: "/resetpassword",
    element: <><Navbar /><RouteTitleUpdater /><ForgotPassword /><Footer /></>
  },
  {
    path: "/terms-conditions",
    element: <><Navbar /><RouteTitleUpdater /><TermsAndConditionPage /><Footer /></>
  },
  {
    path: "/privacy-policy",
    element: <><Navbar /><RouteTitleUpdater /><PrivacyPolicy /><Footer /></>
  },
  {
    path: "/about-us",
    element: <><Navbar /><RouteTitleUpdater /><AboutUs /><Footer /></>
  },
  {
    path: "/coursedetail",
    element: <><Navbar /><RouteTitleUpdater /><CourseDetail /><Footer /></>
  },
  {
    path: "/contact-us",
    element: <><Navbar /><RouteTitleUpdater /><ContactUs /><Footer /></>
  },
  {
    path: "/*",
    element: <><Navbar /><RouteTitleUpdater /><NotFound /><Footer /></>
  },
  {
    path: "/courses/1",
    element: <><Navbar /><RouteTitleUpdater /><Course1 /><Footer /></>
  },
  {
    path: "/courses/2",
    element: <><Navbar /><RouteTitleUpdater /><Course2 /><Footer /></>
  },
  {
    path: "/courses/3",
    element: <><Navbar /><RouteTitleUpdater /><Course3 /><Footer /></>
  },
  {
    path: "/courses/4",
    element: <><Navbar /><RouteTitleUpdater /><Course4 /><Footer /></>
  },
  {
    path: "/courses/5",
    element: <><Navbar /><RouteTitleUpdater /><Course5 /><Footer /></>
  },
  {
    path: "/courses/6",
    element: <><Navbar /><RouteTitleUpdater /><Course6 /><Footer /></>
  },
  {
    path: "/courses/7",
    element: <><Navbar /><RouteTitleUpdater /><Course7 /><Footer /></>
  },
  {
    path: "/courses/8",
    element: <><Navbar /><RouteTitleUpdater /><Course8 /><Footer /></>
  },
  {
    path: "/courses/9",
    element: <><Navbar /><RouteTitleUpdater /><Course9 /><Footer /></>
  },
  {
    path: "/courses/10",
    element: <><Navbar /><RouteTitleUpdater /><Course10 /><Footer /></>
  },
  {
    path: "/courses/11",
    element: <><Navbar /><RouteTitleUpdater /><Course11 /><Footer /></>
  },
  {
    path: "/courses/12",
    element: <><Navbar /><RouteTitleUpdater /><Course12 /><Footer /></>
  },
  {
    path: "/courses/13",
    element: <><Navbar /><RouteTitleUpdater /><Course13 /><Footer /></>
  },
  {
    path: "/courses/14",
    element: <><Navbar /><RouteTitleUpdater /><Course14 /><Footer /></>
  },
  {
    path: "/courses/15",
    element: <><Navbar /><RouteTitleUpdater /><Course15 /><Footer /></>
  },
  {
    path: "/courses/16",
    element: <><Navbar /><RouteTitleUpdater /><Course16 /><Footer /></>
  },
  {
    path: "/faq",
    element: <><Navbar /><RouteTitleUpdater /><FAQ /> <Footer /></>
  },
  {
    path: "/blogs",
    element: <><Navbar /><RouteTitleUpdater /><Blogs />  <Footer /></>
  },
  {
    path: "/discover-more",
    element: <><Navbar /><RouteTitleUpdater/><DiscoverPage/> <Blogs/>  <Footer /></>
  },
  {
    path: "/profile-settings",
    element: <><Navbar /><RouteTitleUpdater/> <ProfileSettings/> <Footer /></>

  },
  {
    path: "/enroll-success/:courseId",
    element: <><Navbar /><RouteTitleUpdater/> <BuyNow/> <Footer /></>

  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
