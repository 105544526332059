import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const titles = {
      "/": "JUZZ-IT",
      "/login": "Login",
      "/signup": "Sign Up",
      "/resetpassword": "Reset Password",
      "/terms-conditions": "Terms & Conditions",
      "/privacy-policy": "Privacy Policy",
      "/about-us": "About Us",
      "/coursedetail": "Course Details",
      "/contact-us": "Contact Us",
      "/faq": "FAQs",
      "/blogs": "Blogs",
      "/courses/1": "Full Stack Development",
      "/courses/2": "Data Science",
      "/courses/3": "Business Analysis",
      "/courses/4": "Hybrid Electric Vehicle Design and Analysis",
      "/courses/5": "Electric Vehicle Design and Analysis",
      "/courses/6": "Cybersecurity",
      "/courses/7": "Digital Marketing",
      "/courses/8": "HR Management",
      "/courses/9": "AI & ML",
      "/courses/10": "Generative AI",
      "/courses/11": "Cloud Computing and DevOps",
      "/courses/12": "Accounts & Finance",
      "/courses/13": "Medical Technology",
      "/courses/14": "High-Rise Building Design & Analysis",
      "/courses/15": "MERN Stack",
      "/courses/16": "DSA & System Design",
      "/discover-more": "Discover more",
      "/profile-settings": "Profile Settings",
      "/student-dashboard": "Student dashboard",
    };

    // Set default title if the path is not in the object
    document.title = titles[location.pathname] || "NOT FOUND";

  }, [location]);

  return null; // No UI needed
};

export default RouteTitleUpdater;
