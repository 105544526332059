import React, { useEffect } from 'react'
import about from "../assets/about.png";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);

  }, []);
  return (
    <>
      <div className='flex flex-col justify-center bg-gradient-to-t from-black via-slate-800 to-purple-800 text-white selection:bg-purple-200 selection:text-black'>

        <div className='text-4xl text-center font-bold pt-10'>
          About Us
        </div>
        <div className='p-4 text-lg'>
          JUZZ-IT is India's Biggest  E-Learning
          Platform Certified by an International
          Standard Organization (ISO 9001
          2015) and Teacher Scientists Network
          (TSN).
          Accredited By: EUAS - Euro Universal
          Accreditation Systems - USA Address:
          1401 Morris Road, Building 1, Suite
          600, Alpharetta, GA 30004, Georgia,
          (USA) Website: www.euas-ac.org
        </div>
        <div className='w-full flex justify-center'>
          <img src={about} alt="about image" className='rounded-3xl md:w-3/5 md:h-[27rem]  p-3' />
        </div>
        <div className='p-10'>

          <div className='text-4xl text-start font-bold pt-10 pb-4'>
            World-Class Mentors
          </div>
          <div>
            Get taught by industry experts having years of experience
            in their respective fields apart from having cracked various
            top tech companies worldwide.
          </div>
          <div className='text-4xl text-start font-bold pt-10 pb-4'>
            One-on-One Mentorship
          </div>
          <div>
            Students will have mentors who will help them with their
            doubts, provide guidance, and help them out 24×7.
          </div>
          <div className='text-4xl text-start font-bold pt-10 pb-4'>
            Wherever You Go
          </div>

          <div>
            Join a network of thousands of students from across the
            globe to support our motive of Learn, Connect, and Grow!
          </div>
          <div className='text-4xl text-start font-bold pt-10 pb-4'>
            Top-Notch Online Lectures
          </div>
          <div>
            Join our three-six month-long series of Online lectures
            with top mentors, recorded sessions, and teaching
            assistants.
          </div>
          <div className='text-4xl text-start font-bold pt-10 pb-4'>
            What We Do?
          </div>
          <div>
            We enroll students and we train them on advenced
            Engineering programs. They spend 500 to 600 hours on
            these tools and work on at least 35+ projects during this
            time period and build a portfolio that they can showcase to
            that potential recruiter and get recruited. At the end of the
            day, for a student to become a very good engineer he
            needs to work on projects, and that’s what we focus on.
            We help them work on industry-relevant projects that will
            enable them to become a better engineer.
          </div>
          <div className='text-2xl font-bold pt-14 pb-5'>
            Unlock your full potential and
            shape a
            brighter future with our
            transformative courses. Join our
            community of eager learners today
            and embark on a journey of
            knowledge, growth, and limitless
            possibilities.
          </div>
        </div>

      </div>
    </>
  )
}

export default AboutUs
