import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EnrolledCourses = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    // Fetch courses from API
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const token = localStorage.getItem("token");

                const response = await fetch(`${BACKEND_URL}/api/v1/courses/enrolled`, {
                    method: "GET",
                    headers: {
                        Authorization: `${token}`,
                    },
                });

                const data = await response.json();
                console.log("API Response:", data);

                if (Array.isArray(data)) {
                    setCourses(data);
                } else if (data?.courses && Array.isArray(data.courses)) {
                    setCourses(data.courses);
                } else {
                    setCourses([]);
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        fetchCourses();
    }, []);


    return (
        <div className="min-h-screen bg-black text-white py-10">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-bold text-center mb-6">My Enrolled Courses</h1>

                {courses.length === 0 ? (
                    <p className="text-center text-gray-500">No enrolled courses yet.</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {courses.map((course) => (
                            <div key={course.id} className="bg-gradient-to-t from-black via-slate-900 to-purple-700 shadow-lg rounded-lg overflow-hidden">
                                <img
                                    src={course.thumbnail}
                                    alt={course.title}
                                    className="w-full h-40 object-cover"
                                />
                                <div className="p-4">
                                    <h2 className="text-xl font-semibold">{course.title}</h2>
                                    <button
                                        onClick={() => navigate(`/course/${course.id}`)}
                                        className="mt-4 bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-lg w-full"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EnrolledCourses;
