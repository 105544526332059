import React from "react";

function CommonQuestions() {
  const faqs = [
    { question: "What is JUZZ-IT?", answer: "JUZZ-IT is an online platform that provides various courses to empower learners with IT skills and expertise." },
    { question: "Do JUZZ-IT courses come with certificates?", answer: "Yes, all courses come with completion certificates that are industry-recognized." },
    { question: "Is there tutor support available on JUZZ-IT?", answer: "Yes, our courses include tutor support to guide you through your learning journey." },
    { question: "How do I register on JUZZ-IT?", answer: "Simply visit our website, create an account, and enroll in your desired course." },
    { question: "Can I access JUZZ-IT courses at any time?", answer: "Yes, all our courses are available 24/7, allowing you to learn at your convenience." },
    { question: "Are JUZZ-IT courses suitable for beginners?", answer: "Absolutely! We offer beginner-friendly courses as well as advanced-level training." },
    { question: "How do I make payments for JUZZ-IT courses?", answer: "Payments can be made securely via credit card, debit card, or online banking." },
    { question: "Does JUZZ-IT offer free trials?", answer: "Yes, we offer free trial lessons for selected courses to help you decide." },
  ];

  return (
    <div
      style={{
        background: "radial-gradient(circle, rgba(128,90,213,0.5) 0%, rgba(13,17,23,1) 100%)",
        color: "#fff",
        padding: "50px 0",
        margin: "0",
        width: "100%",
      }}
    >
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
        <h3 className="text-center mb-4" style={{ fontWeight: "bold" }}>
          Common Questions
        </h3>
        <p className="text-center mb-5" style={{ color: "#ccc", fontSize: "1rem" }}>
          If you have any questions, we are here to help.
        </p>
        <div className="row">
          {faqs.map((faq, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="d-flex align-items-start">
                {/* Icon */}
                <div className="w-fit h-fit rounded-full bg-purple-600 px-3 py-1 m-2">
                  <span style={{ color: "#fff", fontSize: "1.5rem", fontWeight: "bold" }}>?</span>
                </div>
                {/* Question and Answer */}
                <div>
                  <h5 style={{ color: "#fff", fontWeight: "bold", marginBottom: "10px" }}>
                    {faq.question}
                  </h5>
                  <p style={{ color: "#ccc", fontSize: "1rem", marginBottom: "0" }}>
                    {faq.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommonQuestions;
