import Blobs from "../assets/hero-section-blogs.svg"
import { HoverBorderGradient } from "./ui/hover-border-gradient"

function Header() {

  return (
    <header>

      {/* Hero Section */}
      <div className="relative bg-[#0d1117] min-h-[80vh] flex items-center justify-between px-8 md:px-16 lg:px-24 rounded-b-3xl">
        {/* Left Content */}
        <div className="text-white bg-transparent max-w-2xl !text-center md:!text-left flex flex-col items-center md:items-start justify-center">
          <HoverBorderGradient
            containerClassName="rounded-full"
            as="button"
            className="dark:bg-transparent  text-black dark:text-white flex items-center space-x-2"
          >
            <span className="text-purple-400  text-xs font-semibold uppercase  ">
              Empower Your IT Journey with JUZZ-IT
            </span>
          </HoverBorderGradient>
          <h1 className="text-4xl md:text-5xl font-bold mt-3 leading-tight">
            Join our platform to unlock endless opportunities in IT and design{" "}
            <span className="text-purple-400">expert courses.</span>
          </h1>
          <p className="text-gray-300 mt-2">
            Unlock your IT potential with JUZZ-IT. Expert-led courses in IT and design to elevate your career journey.
          </p>
          <a
            href="/discover-more"
            className="inline-block mt-4 px-6 py-3 bg-purple-500 text-white font-semibold rounded-lg shadow-md hover:bg-purple-600 transition"
          >
            Discover more
          </a>
        </div>

        {/* Right Content */}
        <div className="relative hidden md:block ml-auto">
          {/* Gradient Blobs */}

          <img
            src="/images/Hero_section.png"
            alt="Hero Section"
            className="max-w-md lg:max-w-lg rounded-lg relative z-10"
          />
          <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
            <img
              src={Blobs || "/placeholder.svg"}
              alt=""
              className="h-12 w-auto md:h-[150rem] md:w-auto overflow-hidden"
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

