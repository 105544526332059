
import { useEffect } from "react";
import { FiClock, FiArrowRight } from "react-icons/fi"

const blogPosts = [
  {
    id: 1,
    title: "What is Ledger as a Service (LaaS)?",
    description:
      "Learn how blockchain technology is transforming business operations through innovative ledger solutions.",
    image: "https://images.unsplash.com/photo-1667984510023-160473b6728e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bGVkZ2VyJTIwY3J5cHRvfGVufDB8fDB8fHww",
    readTime: "5 min read",
  },
  {
    id: 2,
    title: "True Power of Smart Contracts",
    description: "Discover how smart contracts are revolutionizing digital agreements and transactions.",
    image: "https://images.unsplash.com/photo-1676911809759-77bb68b691c9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c21hcnQlMjBjb250cmFjdHN8ZW58MHx8MHx8fDA%3D",
    readTime: "4 min read",
  },
  {
    id: 3,
    title: "Blockchain Security Measures",
    description: "Essential security practices for protecting blockchain applications and data.",
    image: "https://plus.unsplash.com/premium_photo-1674506654010-22677db35bdf?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8QmxvY2tjaGFpbiUyMFNlY3VyaXR5fGVufDB8fDB8fHww",
    readTime: "6 min read",
  },

]

const Blogs = () => {
  useEffect(() => {
      window.scroll(0, 0);
  
    }, []);
  return (
    <div className="min-h-screen bg-[#12141D]">
      {/* Hero Section */}
      <div className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold text-white mb-4">Latest News and Resources</h1>
          <p className="text-gray-300 max-w-3xl text-xl ">
            Stay updated with the latest insights, tutorials, and resources about technology and development.
          </p>
        </div>
      </div>


      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold text-white mb-8">Most Popular</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <article
              key={post.id}
              className=" rounded-lg shadow-[2px_4px_16px_0px_rgba(248,248,248,0.06)_inset] overflow-hidden border border-[rgba(255,255,255,0.10)] bg-[rgba(40,40,40,0.70)] hover:shadow-xl transition-shadow duration-300"
            >
              <div className="relative h-48 bg-blue-100">
                <img
                  src={post.image || "/placeholder.svg"}
                  alt={post.title}
                  className="w-full h-full object-cover object-center"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-white mb-2">{post.title}</h3>
                <p className="text-gray-300 mb-4 line-clamp-2">{post.description}</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-gray-500">
                    <FiClock className="w-4 h-4 mr-1" />
                    <span className="text-sm">{post.readTime}</span>
                  </div>
                  <button className="flex items-center text-violet-500 hover:text-violet-600 transition-colors">
                    <span className="mr-1">Continue reading</span>
                    <FiArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>

    </div>
  )
}

export default Blogs

