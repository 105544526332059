import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { courses } from "./coursesData";

function Courses() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const navigate = useNavigate();
  const location = useLocation();

  const handleBuyNow = async (courseId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(`/login`);
      return;
    }
    try {
      const response = await axios.get(`${BACKEND_URL}/api/v1/auth/me`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        navigate(`/enroll-success/${courseId}`); 
      }
    } catch (error) {
      navigate(`/login`);
    }
  };
  


  return (

    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-center text-white mb-4">Latest Courses</h2>
        <p className="text-gray-400 text-center mb-12 max-w-2xl mx-auto">
          Discover our range of courses in AI, cloud computing, finance, and medical technology. Gain the skills you
          need to excel in your career.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {courses.map((course) => (
            <div
              key={course.id}
              className="bg-gradient-to-r  !border !border-[#30363d] bg-gray-800 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 rounded-xl overflow-hidden transition-transform hover:scale-105"
            >
              <div className="relative h-48">
                <img
                  src={course.image || "/placeholder.svg"}
                  alt={course.title}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="!p-5 space-y-4 text-left">
                <h3 className="text-white font-semibold text-lg leading-tight">{course.title}</h3>

                <div className="flex items-center justify-between">
                  <span className="text-purple-500">
                    <span className="line-through text-gray-400">₹{course.originalPrice}</span> ₹
                    {course.discountedPrice}+GST
                  </span>
                  <span className="text-green-500 text-sm font-semibold">
                    {Math.round((1 - course.discountedPrice / course.originalPrice) * 100)}% OFF
                  </span>
                </div>
                <div className="flex items-center justify-between gap-3">
                  <Link to={`/courses/${course.id}`} className="w-full">
                    <button className="w-full py-2.5 px-4 border border-violet-500 bg-transparent hover:!bg-violet-600 hover:text-white text-violet-500 rounded-lg transition-colors">
                      Explore
                    </button>
                  </Link>

                  <Link className="w-full">
                    <button onClick={() => handleBuyNow(course.id)} className="w-full py-2.5 px-4 bg-violet-500 hover:bg-violet-600 text-white rounded-lg transition-colors">
                      Buy now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Courses

