import { useState, useEffect } from "react"
import axios from "axios"
import { FaBook, FaExclamationCircle } from "react-icons/fa"

const StudentDashboard = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const [courses, setCourses] = useState([])
  const [user, setUser] = useState(null)
  useEffect(() => {
    const token = localStorage.getItem("token")
    const userData = localStorage.getItem("user")
    const parsedUserData = JSON.parse(userData)
    setUser(parsedUserData)
    try {

      // Fetch enrolled courses
      axios
        .get(`${BACKEND_URL}/api/v1/courses/enrolled`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          console.log("Enrolled courses:", response.data)
          setCourses(response.data)
        })
        .catch((err) => {
          console.log("Error fetching enrolled courses: ", err.message)
        })
    } catch (error) {
      console.log("Error fetching user data: ", error.message)
    }
  }, [])


  return (
    <div className="min-h-screen bg-gray-900 text-violet-100 p-8">
      {user && (
        <div className="mb-10 bg-gray-800 rounded-lg p-6 shadow-lg">
          <h2 className="text-4xl font-semibold text-violet-300">Welcome, {user.fullName}</h2>
        </div>
      )}
      {courses.length === 0 ? (
        <div className="text-center">
          <FaExclamationCircle className="text-gray-700 text-5xl mb-4 mx-auto" />
          <p className="text-3xl text-gray-700">You are not enrolled in any courses yet.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map((course) => (
            <div
              key={course._id}
              className="bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-violet-500/20 transition duration-300"
            >
              <div className="flex items-center mb-4">
                <FaBook className="text-violet-400 text-2xl mr-3" />
                <h2 className="text-xl font-semibold">{course.title}</h2>
              </div>
              <p className="text-gray-400 mb-4">{course.description}</p>
              <div className="flex justify-between items-center">
                <span className="text-violet-300">
                  {course.instructor && typeof course.instructor === "object"
                    ? course.instructor.fullName || "Unknown Instructor"
                    : course.instructor}
                </span>
                <button className="bg-violet-600 hover:bg-violet-700 text-white px-4 py-2 rounded-md transition duration-300">
                  View Course
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default StudentDashboard

