import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    useEffect(() => {
        window.scroll(0, 0);

    }, []);
    return (
        <>
            <div className='w-full h-screen flex flex-col justify-center items-center bg-gradient-to-t from-black via-slate-900 to-purple-700 text-white gap-4'>
                <div className=' font-bold uppercase text-3xl'>
                    404 - Page not found
                </div>
                <Link to="/" className='font-light text-purple-500'>
                    Back to Home
                </Link>
            </div>

        </>
    )
}
export default NotFound
