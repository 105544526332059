import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

export default function TeacherProtectedRoutes() {
    // Get token from cookies
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const userData = JSON.parse(user);


    // If token exists, render the component, otherwise redirect to login
    return (token && userData.role === 'teacher') ? <Outlet /> : <Navigate to="/login" />;
}