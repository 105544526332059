import React, { useEffect } from 'react'

const TermsAndConditionPage = () => {
  useEffect(() => {
    window.scroll(0, 0);

  }, []);
  
  return (
    <>
      <div>
        <div className='text-center font-bold text-3xl pt-10 bg-purple-900 text-white selection:bg-purple-200 selection:text-black'>Terms and Conditions</div>

        <div className='md:p-24 p-12 flex flex-col gap-3 bg-gradient-to-t from-black via-slate-800 to-purple-900 text-white selection:bg-purple-200 selection:text-black'>

          <div className='flex justify-center'>
            These Terms & Conditions (“Terms” or “ToU”) are JUZZ-IT (along with its successors, subsidiaries, permitted assignees, management, support/sales team, employees, consultants and all persons claiming through them and under them and referred to as the “institutions”, JUZZ-IT”, “us”, “we”, or “our”) and its Users and/or its Minor Users (referred to as “you” or “yourself”). These Terms define the relationship between you and JUZZ-IT in accessing our Services via our Platform and/or at our Offline Centres. By clicking on the “Agree” button you (on behalf of yourself and/or a Minor User) agree to these Terms which will bind you and/or the Minor User. If you do not agree to these Terms you and/or the Minor User must cease to continue to purchase any Services from us. These Terms apply to all Users and/or Minor Users of our Services including on our Website, and without limitation also apply to Users and/or Minor users who are browsers, vendors, and/or Users, and our employees and consultants. The expression “YOU” shall mean the Users/beneficiaries and/or Minor Users of our Services, including, without limitation all persons including our employees and consultants who create and/or make available Course / Course Material on the Platform and users who access the Platform to purchase any Service or users who access the Platform otherwise such as the users who are browsers, vendors, Users, and/or Minor Users, and our employees and consultants. Any new feature or tool which is added to the current Website or Services shall also be subject to these Terms.
            <br /><br />
            We also provide details regarding our processing of the personal data of our Users and/ or Minor Users, and employees and consultants in our Privacy Policy. In all cases, you (on behalf of yourself and/or a Minor User) agree that no separate act is required to make the Privacy Policy, the Placement Policy, or the Terms binding on you and/or a Minor User and that your and/or a Minor User’s act of downloading, registering, accessing, purchasing or using any part of the Website or Services constitutes your (on behalf of yourself and/or a Minor User’s) full and final acceptance of the Privacy Policy, the Placement Policy, and the Terms. You (on behalf of yourself and/or a Minor User) agree and acknowledge to be bound by, and liable under, the Privacy Policy, the Placement Policy, and the Terms. If you (on behalf of yourself and/or a Minor User) do not agree with the Privacy Policy, the Placement Policy, and/or the Terms, please do not use the Website/Services.

            These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 thereunder, as amended from time to time. Please read the Terms, Placement Policy, and Privacy Policy carefully before using, registering, or accessing our Website and Services. In the event of any discrepancy between the Terms and any other policies with respect to our Website or Services, the provisions of these Terms shall prevail.
          </div>

          <div className='text-start font-bold text-2xl'>Definitions</div>
          In these Terms, the following words and expressions, unless inconsistent with the context, shall bear the meanings assigned hereto: Confidential Information – means all information provided by one party to the other in written, graphic, recorded, machine-readable, or another form concerning the business, clients, suppliers, finances and other areas of the other party’s business or products, including, without limitation, the Course Materials, except information that is publicly known through no wrongful act or omission of the User and/or the Minor User or any information already in the possession or control of the disclosing party.
          <br />
          <br />
          Course – means our programs are offered remotely through either our Platform, pre-recorded multimedia, and audio-visual content, live-streamed classes on third-party applications, and/or our programs offered physically at Our Offline Centres.
          <br />
          <br />
          Course Material – means the information provided by JUZZ-IT to accompany a Course as part of the Services which is available on our Platform and includes assessment tests and examinations.
          <br />
          <br />
          Intellectual Property Rights – means copyright, rights in or relating to databases, patent rights, performers’ rights, designs, registered designs, trademarks, rights in or relating to Confidential Information, and other intellectual property rights (registered or unregistered) throughout the world.
          <br />
          <br />
          Job Assistance Program – means the Service described in Clause 13(b) of these Terms. Legal Actions and Notices – means any notice or other communication to be given hereunder will be in writing and given by registered post only.
          <br />
          <br />
          Minor User – means a User of our Services, who is a child or a minor (i.e. a natural person below the age of 18 (eighteen) years).
          <br />
          <br />
          Offline Centre – means physical premises operated by JUZZ-IT to deliver offline classes.
          <br />
          <br />
          Placement Policy – means JUZZ-IT's placement policy as set out in Clause 13 of these Terms.
          <br />
          <br />
          Platform – means the Website and any other feature or tool provided and operated by JUZZ-IT, including any application that may be launched by JUZZ-IT.
          <br />
          <br />
          Pricing and Promotional Policy – means the policy governing the pricing of and promotions related to the Courses offered on the Platform.
          <br />
          <br />
          Privacy Policy – means JUZZ-IT privacy policy available at [https://juzzit.in/privacy].
          <br />
          <br />
          Services – means the provision of the Course and/or the Course Material together with such other services as agreed from time to time and purchased by you (on behalf of yourself and/or the Minor User) and availed remotely through either our Platform, pre-recorded multimedia and audio-visual content, live-streamed classes on third party application and/or physically at our Offline Centres.
          <br />
          <br />
          Support System – refers to the different processes that JUZZ-IT follows to resolve User and/or Minor User queries and includes but are not limited to one-on-one and group doubt-clearing sessions through phone calls, e-mail, third-party text, audio, and video communication applications, or physical at our Offline Centres.
          <br />
          <br />
          Terms – means these Terms and Conditions of JUZZ-IT.
          <br /><br />
          User (or “you”, “your”, “yourself”) – refers to any individual who is a natural person aged 18 (eighteen) years or above, and who is competent and capable of contracting within the meaning of the Indian Contract Act, 1872 and accesses our Website and/or purchases the Services. Such an individual, where applicable, includes the parent/legal guardian or any other authorized individual who facilitates the use of the Services for a Minor User.
          <br />
          <br />
          <div>
            Website – means <a className='text-purple-500' href="http://www.juzzit.in/">http://www.juzzit.in/</a>
          </div>


          <div className='text-start font-bold text-2xl'>General Terms and Conditions</div>
          <div className='text-start font-bold text-xl'>Course Enrolment</div>

          When you and/or a Minor User enroll in our Course, you and/or the Minor User get a license from us to view/access it, depending on the specific program via the Platform, through live-streamed classes on third-party applications and/or physically at our Offline Centres and for no other use. You and/or a Minor User are hereby expressly barred from transferring or reselling Courses in any way.
          <br />
          <br />
          Our Course Material is only licensed to you and/or a Minor User and is not sold to you and/or a Minor User. The license granted to you does not give you and/or the Minor User any right to transfer or resell the Course Material in any manner (including by sharing account information with a purchaser or downloading the Course Material and sharing it on third-party sources).
          <br />
          <br />
          JUZZ-IT grants you as a User and/or a Minor User, a limited, non-exclusive, non-transferable license to access and view the Course Material and associated content for which you (on behalf of yourself and/or the Minor User) have paid all required fees, solely for your personal, non-commercial, educational purposes through the Services, in accordance with these Terms and any conditions or restrictions associated with a particular Course or feature of our Services.
          <br />
          <br />
          All other uses are expressly prohibited. You and/or the Minor User may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, sub-license, or otherwise transfer or use any Course Material unless we give you (for yourself or on behalf of a Minor User) explicit permission to do so in a written agreement signed by a JUZZ-IT's authorized representative.
          <br />
          <br />
          The Course Material may be accessed by you and/or the Minor User through our Platform only irrespective of the Course being delivered remotely or physically. We reserve our right to revoke any license to access and use Course Material at any point in time, in the event that we decide or are obligated to disable access to a Course due to legal or policy reasons, for example, if the Course you and/or the Minor User enrolled in is the object of a copyright complaint, or if we determine its content violates our Terms and policies. The lifetime access is not applicable to add-on features and Services associated with a Course, for example, translation captions of Courses may be disabled by JUZZ-IT at any time, and JUZZ-IT may decide at any time to no longer provide Support System in association with a Course. To be clear, the lifetime access is only to the Course content and not to add-on features and Services associated with a Course.


          <div className='text-start font-bold text-xl'>Accounts</div>
          <div>
            You and/or a Minor User will need an account to purchase and enroll in a Course. You (on behalf of yourself or the Minor User) shall be solely responsible for all activity associated with your and/or the Minor User’s account either done by yourself, the Minor User, or someone using your and/or the Minor User’s account without your permission. If you suspect someone else is using your and/or the Minor User’s account, it is your duty (on behalf of yourself or the Minor User) to inform us by contacting our support team at <a className='text-purple-500' href="mailto:education@juzzit.in">education@juzzit.in</a>. We may request some information from you (on behalf of yourself and/or the Minor User) to confirm that you and/or a Minor User are indeed the owner of your account.
          </div>
          <br />
          When registering, setting up, and maintaining your account or that of a Minor User, you agree and acknowledge to provide and continue to provide accurate and complete information, including a valid email address and the Company does not have the responsibility to ensure or verify that you and/or the Minor User conform to eligibility criteria, wherever applicable. You (on behalf of yourself and/or the Minor User) shall not transfer or assign your and/or the Minor User’s account to someone else or use someone else’s account without their permission. If you (on behalf of yourself and/or the Minor User) contact us to request access to an account, we will not grant you such access unless you can provide us with the login credential information for that account. In the event of the death of a User and/or the Minor User, the account of that User and/or a Minor User will be closed.

          <div className='text-start font-bold text-xl'>Important Note to Minor Users</div>

          Every Minor User should seek the prior consent and agreement of their parents/legal guardians to these Terms, the Placement Policy, and the Privacy Policy before providing any information about themselves, their parents, and/or other family members and before accessing or using the Platform/Website/Services. If you as a Minor User, are using our Services without a parent or legal guardian’s consent, please immediately stop using our Services until one of your parents or legal guardians has approved and provided their informed consent to your usage of our Services in accordance with the Terms, the Placement Policy and the Privacy Policy.

          <div className='text-start font-bold text-xl'>Important note to parents and legal guardians</div>

          If you have the ability to enter into a legally binding contract under applicable laws and you register, purchase, approve, and/or consent to the usage of the Services by a Minor User or facilitate the use of our Services for a Minor User, you represent and warrant that you are such Minor User’s parent or legal guardian, and you agree to be bound by these Terms, the Placement Policy and the Privacy Policy on behalf of such Minor User, including without limitation, being liable for all use of the Services by the Minor User and that no separate act is required to make these Terms, the Placement Policy and the Privacy Policy binding on you, and that you and/or the Minor User’s act of downloading, registering, purchasing, and/or using the Services constitutes your complete acceptance of these Terms, the Placement Policy and the Privacy Policy, on behalf of such Minor User.
          <br />
          <br />
          You agree and acknowledge that the use of the Services by a Minor User will be undertaken pursuant to your consent and knowledge and under your supervision. In other words, wherever applicable, in the event a Minor User utilizes the Services, it is assumed that such Minor User has obtained your consent, i.e. the consent of their parent/legal guardian.
          <br />
          <br />
          Further, you hereby covenant that you will cause the Minor User to use the Services strictly in accordance with these Terms, Placement Policy, and Privacy Policy, and all terms contained herein shall be enforceable against you and you shall be responsible for all losses or liabilities incurred by the Company due to any breach of these Terms, Placement Policy, and Privacy Policy by the Minor User. You hereby acknowledge that the Company may choose, but is not obligated, to make any inquiries, either directly or through third parties, that the Company deems necessary to validate your information and status as a parent or a legal guardian unless required under applicable laws.

          <br />
          <br />

          <div className='uppercase'>
            NOT WITH STANDING THE FOREGOING, YOU ACKNOWLEDGE THAT THE COMPANY CAN NOT GUARANTEE THE ACCURACY OF ANY INFORMATION SUBMITTED BY ANY USER (INCLUDING A MINOR USER) AND THE COMPANY IS NOT OBLIGATED TO VERIFY THE IDENTITY OF ANY USER, INCLUDING ANY USER’S CLAIM TO BE A PARENT OR A LEGAL GUARDIAN, OR TO VERIFY THAT A PARENT OR THE LEGAL GUARDIAN IDENTIFIED BY A MINOR USER DURING REGISTRATION OR USAGE IS SUCH MINOR USER’S ACTUAL PARENT OR LEGAL GUARDIAN. A USER ACCOUNT, ALONG WITH ALL ASSOCIATED MINOR USER ACCOUNTS, IF ANY, MAY BE TERMINATED BY THE COMPANY AT ANY TIME AND WITHOUT WARNING FOR ANY FAILURE TO ABIDE BY ANY OF THESE TERMS.
          </div>

          <br />
          You and/or a Minor User can terminate your account at any time after due intimation to our support team and by following the procedures as indicated by our support team. Please check our Privacy Policy [https://juzzit.in/privacy] to see what happens when you terminate your and/or the Minor User’s account.

          <div className='text-start font-bold text-xl'>Payments</div>


          Course pricing and related information is listed on the Website and/or other third-party platforms for each Course and is subject to changes without notice. The prices of Courses on JUZZ-IT are determined based on our Pricing and Promotional Policy. If you and/or a Minor User are logged into your and/or the Minor User’s account, the listed currency you and/or a Minor User see is based on your and/or a Minor User’s location when you (on behalf of yourself and/or the Minor User) created your and/or the Minor User’s account. If you and/or the Minor User are not logged into your and/or the Minor User’s account, the price currency is based on the country where you and/or a Minor User are located. We do not enable Users and/or Minor Users to see pricing in other currencies. If you are a User and/or Minor User located in a country where use and sales tax, goods and services tax, or value-added tax is applicable to consumer sales, we are responsible for collecting and remitting that tax to the proper tax authorities.
          <br />
          <br />
          When you (on behalf of yourself and/or a Minor User) make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you and/or the Minor User still get access to the Course you and/or the Minor User are enrolling in, you (on behalf of yourself and/or a Minor User) agree to pay us the corresponding fees within 30 (thirty) days of notification from us. We reserve the right to disable access to any of our Services including any Course for which we have not received adequate payments. We use third-party payment gateways and/or aggregators to process payments applicable to the Services including the Courses offered by us. Similarly, we have also enabled the integration of third-party payment providers including without limitation the third-party credit facility providers to facilitate better payment options to you (on behalf of yourself and/or a Minor User), which may vary depending on your and/or the Minor User’s location. Third-party payment gateways/aggregators, third-party credit facility providers, digital lending apps/platforms, lending service providers, financial services intermediaries, or any other third-party facilitating financing and/or payment-related services shall collectively be referred to as “Third-Party Service Providers”.
          <br />
          <br />
          Third-Party Service Providers may also charge you (on behalf of yourself and/or a Minor User) fees to use or access their services and may require your and/or a Minor User’s Personal Information (as defined in the Privacy Policy) to complete any transaction on the Platform and/or at our Offline Centres. Further, to facilitate completion of your (on behalf of yourself and/or a Minor User) payments to us through the Platform or avail the payment options provided to you, you may be redirected to an external website operated by the Third-Party Service Provider. We cannot and do not <br /><br />(i) guarantee the adequacy of the privacy and security practices employed by or the content and media provided by the Third-Party Service Provider or its respective websites;
          <br /><br />
          or
          <br /><br />
          (ii) control collection or use of your and/or a Minor User’s Personal Information (as defined in the Privacy Policy) by such Third-Party Service Provider.
          Hence, prior to using any services offered by a Third-Party Service Provider, we suggest that you (on behalf of yourself and/or a Minor User) read their terms and conditions, privacy policy, and other policies, that may apply, to understand their terms of usage and to understand how your and/or a Minor User’s Personal Information (as defined in the Privacy Policy) is being processed. JUZZ-IT is not affiliated to any Third-Party Service Provider and neither JUZZ-IT nor any of the Third-Party Service Provider are agents or employees of the other.
          <br /><br />
          Further, pursuant to the payment option you (on behalf of yourself and/or a Minor User) may choose, you (on behalf of yourself and/or a Minor User) may be required to enter into a separate agreement with the relevant Third-Party Service Provider. The agreement with the Third-Party Service Provider is an independent contract or agreement between you (on behalf of yourself and/or a Minor User) and such Third-Party Service Provider and JUZZ-IT is not privy to any agreement or arrangement you (on behalf of yourself and/or a Minor User) may enter into with them. You (on behalf of yourself and/or a Minor User) will be bound by the terms and conditions of your agreement or arrangement with such Third�Party Service Provider and JUZZ-IT will not in any way be liable or responsible for or in relation to any such arrangement. JUZZ-IT is only facilitating various payment options to you (on behalf of yourself and/or a Minor User) and is not offering any payment or loan by itself in any manner.
          <br /><br />
          You (on behalf of yourself and/or a Minor User) agree that you are solely responsible for all charges that occur through such Third-Party Service Providers and acknowledge and agree to indemnify, defend, and hold harmless JUZZ-IT, its affiliates, their respective officers, directors, employees, and agents from any loss arising out of or related to the use of the Platform or any purchases made through the Platform.
          <br /><br />
          This obligation will survive your and/or a Minor User’s use of our Services and termination of your Agreement with us. For purposes of these Terms, “Loss” means all losses, liabilities, damages, awards, settlements, claims, suits, proceedings, costs, and expenses (including reasonable legal fees and disbursements and costs of investigation, litigation, settlement, judgment, interest, and penalties). JUZZ-IT shall not be liable to you and/or a Minor User for any claims arising out of any act or omission on the part of the Third-Party Service Provider(s) including, but not limited to, any lost, stolen, or incorrectly processed payments. JUZZ-IT expressly disclaims any responsibility and liability for all services provided by the Third-Party Service Provider(s).
          <br /><br />

          <div className='text-start font-bold text-xl'>Refund Policy</div>


          All Course purchases are NON-REFUNDABLE and JUZZ-IT shall not be obligated to entertain or accept any refund requests for any kind of payments made (including, whether pre-booking, installments, or full payments).
          You and/or the Minor User shall only use JUZZ-IT for lawful purposes. We reserve the right to disable, deactivate, suspend, and/or ban your account and/or access to any Course, Platform, and/or the Services in the event you violate applicable local or national laws or regulations or are in repeated or serious violations or breach of these Terms, Privacy Policy and Placement Policy. As mandated by the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 and to the extent it is applicable to these Terms and the Privacy Policy, JUZZ-IT hereby informs you and/or the Minor User that, in the use of the Services, you and/or the Minor User are not permitted to host, display, upload, send, modify, publish, transmit, update or share any information, that:
          <ul className='list-disc'>

            <li>
              belongs to another person and to which you do not have any right.
            </li>
            <br />
            <li>
              is obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, including bodily privacy, hateful, harassing on the basis of gender, racial or ethnically objectionable, relating to or encouraging money laundering or gambling, or promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence.
            </li>
            <br />
            <li>
              is harmful to child.
            </li>
            <br />
            <li>
              infringes any patent, trademark, copyright or other proprietary rights.
            </li>
            <br />
            <li>
              deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any misinformation or information which is patently false and untrue or misleading in nature.
            </li>
            <br />
            <li>
              impersonates another person.
            </li>
            <br />
            <li>
              contains software viruses, or any other computer code, files or program designed to interrupt, destroy or limit the functionality of any computer resource.
            </li>
            <br />
            <li>
              violates any law for the time being in force.
            </li>
          </ul>

          <br />
          <br />
          JUZZ-IT has discretion in enforcing these Terms. We may terminate or suspend your and/or the Minor User’s permission to use our Platform and Services or ban your account at any time, with or without notice, for any violation of these Terms, if you (for yourself and/or the Minor User) fail to pay any fees when due, for extended periods of inactivity, for unexpected technical issues or problems, or if we suspect that you and/or the Minor User engage in fraudulent or illegal activities. Upon any such termination, we may delete your and/or the Minor User’s account and content, and we may prevent you and/or the Minor User from further access to the Platforms and use of our Services. Your and/or the Minor User’s content may still be available on the Platforms even if your and/or the Minor User’s account is terminated or suspended. You (on behalf of yourself and/or the Minor User) agree that we will have no liability to you, to the Minor User or any third party for termination of your and/or the Minor User’s account, removal of your and/or the Minor User’s content, or blocking of your and/or the Minor User’s access to our Platforms and Services.
          <br />
          <br />

          <div className='text-start font-bold text-xl'>Support System</div>

          The Support System provided by JUZZ-IT should be strictly used only for getting help with the materials discussed in the Course. The Support System shall not be provided for side projects or assignments that the User and/or the Minor User might work on in their individual capacity. The duration of the support will depend on the plan that you (on behalf of yourself and/or the Minor User) have purchased. If you (on behalf of yourself and/or the Minor User) miss to make payments then access to the Support System will be cut off. If the User and/or Minor User is caught sharing videos, notes, programs, and other digital content that is owned by JUZZ-IT then appropriate legal actions would be taken and the User (on behalf of themselves and/or the Minor User) has to bear all the associated costs.
          <br />
          <br />
          <div className='text-start font-bold text-xl'>Third-Party Software Licenses</div>

          For certain Courses, JUZZ-IT provides legal software licenses for the Users and/or Minor Users to practice. The license for the Course Material will be available only for the duration of the Course and must be used only for learning the Course for which the license was provided. The use of these licenses for commercial activities will result in an immediate loss of access to the Course, the Support System, and any certificates. Commercial use of the Courses by the User and/or the Minor User will also lead to legal prosecution.
          <br />
          <br />

          <div className='text-start font-bold text-xl'>JUZZ-IT’s Rights</div>

          We own the Platform and Services, including the Website, present or future applications and Courses, Course Material, our logos, API, database, code, and content created and/or delivered by our employees, consultants. You and/or a Minor User cannot tamper with those or use them without authorization.
          All right, title, and interest in and to the Platform and Services, including our Website, our existing or future applications, our logos, APIs, databases, code, and the content our employees or partners submit or provide through our Services are and will remain the exclusive property of JUZZ-IT. Our Platforms and Services are protected by copyright, trademark, and other laws. Nothing gives you and/or a Minor User a right to use the JUZZ-IT name or any of the JUZZ-IT trademarks, logos, domain names, and any other Intellectual Property Rights.
          <br />
          <br />

          Security, Intrusion & Detection You and/or the Minor User may not do any of the following while accessing or using the Platform and Services: access, tamper with or use non-public areas of the Platform, JUZZ-IT’’s computer systems, or the technical delivery systems of JUZZ-IT service providers. disable, interfere with, or try to circumvent any of the features of the Platform related to security or probe, scan, or test the vulnerability of any of our systems. copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the JUZZ-IT Platform or Services.
          <br />
          <br />
          Access or search or attempt to access or search our Platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our Website, mobile apps, or API (and only pursuant to those API terms and conditions). You may not scrape, spider, use a robot or use other automated means of any kind to access the Services in any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as JUZZ-IT), or interfere with, or disrupt, (or attempt to do so), the access of any User and/or Minor User, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Platforms or Services, or in any other manner interfering with or creating an undue burden on the Services.
          <br />
          <br />
          Unauthorized axttempts to hack, copy, upload or change information, or otherwise cause harm/damage to the Services and/or any User/Minor User of the Services, is strictly prohibited and may be punishable under applicable law. For Website security purposes, and to ensure that the Service remains available to all genuine human Users and/Minor Users and to genuine businesses and organizations that have the consent of JUZZ-IT, all network traffic is monitored.
          <br />
          <br />
          If a User (on behalf of themselves and/or a Minor User) disagrees with these Terms, then that User and/or Minor User should not use this Website.

          <br />
          <br />

          <div className='text-start font-bold text-xl'>Restriction of Liability and Disclaimer</div>
          JUZZ-IT reserves the right, at its sole discretion, to restrict or block the access of a User and/or a Minor User, and/or to suspend or terminate the account of a User and/or a Minor User, and/or to remove or delete any information/content posted, uploaded or displayed by a User and/or a Minor User, and/or to deny a User and/or a Minor User’s future access to the Platform and/or the Service, or any part thereof, with or without notice and for any reason whatsoever, and shall have no liability or responsibility to the User and/or a Minor User in any manner whatsoever if it chooses to do so. The Platform, Services, Course and Course Material are provided on an “as is” basis without warranties of any kind, either express or implied. For all Users and/or a Minor Users as well as any entities or third parties mentioned or displayed anywhere on this Platform or as part of the Services, JUZZ-IT expressly disclaims all liability for errors and omissions in the contents of this Platform or as part of its Services, or any inaccuracies, falsehoods, defamation, slander, libel, discriminatory terminology, obscenity, pornography or profanity you and/or a Minor User may encounter or any interpretation of such that you and/or a Minor User may make. JUZZ-IT disclaims all warranties, express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular use or purpose, title and non�infringement with respect to the Platform, Services, Course and Course Material. JUZZ-IT further does not warrant that the Website, the Services, access to the Course or Course Material will be uninterrupted or error free; nor is there any warranty as to the results that may be obtained from the use of the Website, the Services, access to the Course or Course Material.
          <br />
          <br />

          As a provider of interactive online and offline Services such as, but not limited to, job boards, school ad listings, public forum boards, or banner advertisements, JUZZ-IT is not liable for any words, terminology, statements, representations, images, visuals or content provided by or viewed by a User and/or a Minor User. The information and images displayed therein are provided in good faith, but we make no guarantees about the completeness, accuracy, timeliness, availability, accessibility, merchantability, appropriateness, or fitness for any particular purpose of any of the information, images or visuals. The User (on behalf of themselves and/or the Minor User) accepts that the use of this Platform and of our Services is at the User’s/Minor User’s sole risk. The User (on behalf of themselves and/or the Minor User) is aware and voluntarily and wholly accepts that your and/or the Minor User’s use of this Platform, of our Services or any other JUZZ-IT website or application is always entirely and exclusively your own responsibility. JUZZ-IT accepts no responsibility for your and/or the Minor User’s use of this Platform and of our Services, or for anyone else’s (including any advertiser’s) use of it. JUZZ-IT accepts no responsibility for any injury, loss, penalty, claim, damage, or interpretation arising out of or in any way connected with this Platform and/or our Services which includes, but is not limited to, the content, information or entities/third parties/advertisers displayed here, the usage of cookies or collection of data/information by this Platform and/or our Services or by entities/third parties/advertisers displayed here.
          <br />
          <br />

          By accessing, browsing, or using this Platform and/or our Services, you (on behalf of yourself and/or the Minor User) agree with these conditions and agree to indemnify, defend and hold harmless the owners, operators, and maintainers of this Platform and of the Services and their agents and partners. To the extent permitted by applicable law, we may decide to cease making available certain features of the Services at any time and for any reason.
          <br />
          <br />
          We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, sabotage, natural disaster, electrical, internet services, or telecommunication outage, or government restrictions. Indemnity and Release.
          <br />
          <br />

          To the extent permitted by applicable law, you (on behalf of yourself and/or the Minor User) agree to defend, indemnify and hold harmless JUZZ-IT, its affiliates, their respective officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from:
          <br />

          <ol className='list-[lower-roman]'>
            <li>
              your and/or a Minor User’s use of and access to the Platform.
            </li>
            <br />
            <li>
              your and/or a Minor User’s violation of any clauses of these Terms.
            </li>
            <br />
            <li>
              your and/or a Minor User’s violation of any third party right, including without limitation any copyright, property, or privacy right.
            </li>
            or
            <br />
            <li>
              breach or violation of the terms and conditions of, and/or default in payment of, any moneys, loans and/or equated monthly instalments under any third party arrangement / agreement.
            </li>
            <br />
            <li>
              violation of any applicable laws. This defense and indemnification obligation will survive these Terms and your and/or a Minor User’s use of the Platform.
            </li>
          </ol>
          <br />

          You (on behalf of yourself and/or the Minor User) hereby expressly release JUZZ-IT, its affiliates and any of their respective officers, directors, employees and agents from and any cost, damage, liability or other consequence of any of the actions/inactions of any third-party vendors or service providers and specifically waive any claims or demands that you and/or a Minor User may have in this behalf against any of JUZZ-IT, its affiliates and any of their respective officers, directors, employees and agents under any statute, contract or otherwise.

          <br />
          <br />
          <div className='text-start font-bold text-xl'>Limitation of Liability</div>

          <div className='uppercase'>
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, JUZZ-IT’S TOTAL LIABILITY TO THE USER AND/OR THE MINOR USER, OR ANYONE CLAIMING BY OR THROUGH THE USER AND/OR THE MINOR USER OR ON THE USER’S AND/OR MINOR USER’S BEHALF, FOR ANY CLAIMS, LOSSES, COSTS, OR DAMAGES ARISING OUT OF, RESULTING FROM, OR IN ANY WAY RELATED TO THE SERVICES, FROM ANY CAUSE, SHALL NOT EXCEED THE COURSE FEES THE USER (ON BEHALF OF THEMSELVES AND/OR THE MINOR USER) PAID TO JUZZ-IT (EXCLUDING TAXES). TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL JUZZ-IT BE LIABLE, FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, SPECULATIVE, INCIDENTAL (INCLUDING ANY DAMAGES INCURRED DUE TO A LOSS OF OPPORTUNITY, INCOME, OR PROFITS, LOSS OF INFORMATION, BUSINESS INTERRUPTION), EXEMPLARY, OR PUNITIVE DAMAGES, OR FOR ATTORNEYS’ FEES, EXPENSES, EXPERT WITNESS FEES, OR COSTS, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, PRODUCT LIABILITY, OR OTHERWISE AND REGARDLESS OF WHETHER SUCH LOSS OR DAMAGE WAS FORESEEABLE OR THE USER AND/OR THE MINOR USER AND/OR JUZZ-IT HAVING BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
          </div>
          <br />
          <br />
          <div className='text-start font-bold text-xl'>Placement Policy</div>

          The Placement Policy governs your placement process with JUZZ-IT and we advise you to go through this Clause to understand what your experience is going to look like. Your consent and acceptance to this Clause is a prerequisite for initiating the placement process with JUZZ-IT and hence, your understanding of this Placement Policy is imperative. Career Services Support Program.
          <br />
          <br />
          Each User registered in a Career Services Support Program must complete his/her profile and adhere to all the following requirements to be eligible for Career Services Support Program. For the purpose of this Placement Policy, “Career Services Support Program” shall collectively mean the Job Assistance Program or any other similar program of JUZZ-IT.
          <br />
          <br />
          The User agrees to provide his/her correct updated profile information including but not limited to information related to Class X, XII, under-graduation, post-graduation, graduation year, marks. The User shall be responsible for the correctness of his/her profile information and JUZZ-IT shall not be held responsible in case such profile information of the User is found to be incorrect.
          <br />
          <br />
          The User should actively reach out to JUZZ-IT for getting his/her profile data updated.
          <br />
          <br />
          The User shall graduate in all mandatory courses applicable for the Career Services Support Program. For the purpose of this Placement Policy, “Graduate” or “Graduation” shall mean completion of all the challenges associated with a course with an average 90% (eighty percent) score. The User shall clear the
          <br />

          <ol className='list-[upper-alpha]'>
            <li>
              domain based test (hereinafter referred to as “Career Service Eligibility Test”) in accordance with the relevant Career Services Support Program; and / or
            </li>
            <br />
            <li>
              the general aptitude based mock MCQ test, if applicable.
            </li>
          </ol>
          The User shall be eligible to appear for Career Service Eligibility Test post completion of all the mandatory courses applicable for the Career Services Support Program in the manner set out in sub-clause (ii) above.
          <br />
          <br />
          JUZZ-IT reserves the right to give placement assistance to the User without conducting
          Career Service Eligibility Test as per its sole discretion.
          <br />
          <br />
          The User shall clear at least 1 (one) internal and 1 (one) external mock interview with a score of 80% (seventy percent) in each such interviews. User can retake the external mock interview up to 5 (five) times and avail internal mock interviews based on the applicable Career Services Support Program availed by the User. Throughout the placement journey of the User, JUZZ-IT may organize multiple sessions relevant to current market requirements viz. ‘refreshers’, ‘HR, aptitude and verbal training’ and ‘fastrack’ sessions etc. These sessions will help User achieve good conversions in interviews. Hence, the User shall maintain at least 80% (eighty percent) attendance in these sessions organized by JUZZ-IT.
          <br />
          <br />

          <div>

            In the event a User opts out of the Career Services Support Program, JUZZ-IT shall not be providing any further assistance to such User. A User can opt out of Career Services Support Program by communicating the same to us at <a className='text-purple-500' href="mailto:education@juzzit.in">education@juzzit.in</a>.
          </div>
          <br />

          In the event a User gets a job placement in a company by himself/ herself after pursuing Career Services Support Program, JUZZ-IT reserves the right not to further assist the User in getting job in any other company under the Career Services Support Program.
          <br />
          <br />
          The User shall be responsible to discuss and finalize the joining date with the hiring partner. User should honor the joining date (after the offer roll out by the hiring partner to the User) as per the offer letter.
          <br />
          <br />
          JUZZ-IT shall not be held responsible for revocation of the job offer of the User in the event such User does not honor the joining date as per the offer letter. In such scenario, JUZZ-IT reserves the right to not provide any further assistance to the User.
          <br />
          <br />
          If a User receives more than 1 (one) offer owing to delay in the announcements of results by the hiring partners, the User is bound to accept the job offer whose results are declared earlier.
          <br />
          <br />
          The User accepts that his/her information can be used by JUZZ-IT for promotional purpose.
          <br />
          <br />
          The User shall mandatorily share his/her offer letter with JUZZ-IT once the same is rolled out by the hiring partner.
          <br />
          <br />
          In the event the User does not continue working with the hiring partner he/she is placed with, JUZZ-IT reserves the right not to further assist the User in getting job with any other hiring partner under the Career Services Support Program.
          <br />
          <br />
          JUZZ-IT highly values relationships with both our Users and hiring partners. Once an interview process has begun, User should participate in all interview rounds till he/she is rejected/sele.

        </div>
      </div>
    </>
  )
}

export default TermsAndConditionPage
