
import { useState } from "react"
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";

const faqs = [
  {
    question: "What is JUZZ-IT?",
    answer:
      "JUZZ-IT is an online platform that provides various courses to empower learners with IT skills and expertise.",
  },
  {
    question: "Do JUZZ-IT courses come with certificates?",
    answer: "Yes, all courses come with completion certificates that are industry-recognized.",
  },
  {
    question: "Is there tutor support available on JUZZ-IT?",
    answer: "Yes, our courses include tutor support to guide you through your learning journey.",
  },
  {
    question: "How do I register on JUZZ-IT?",
    answer: "Simply visit our website, create an account, and enroll in your desired course.",
  },
  {
    question: "Can I access JUZZ-IT courses at any time?",
    answer: "Yes, all our courses are available 24/7, allowing you to learn at your convenience.",
  },
  {
    question: "Are JUZZ-IT courses suitable for beginners?",
    answer: "We offer beginner-friendly courses as well as advanced-level training.",
  },
  {
    question: "How do I make payments for JUZZ-IT courses?",
    answer: "Payments can be made securely via credit card, debit card, or online banking.",
  },
  {
    question: "Does JUZZ-IT offer free trials?",
    answer: "Yes, we offer free trial lessons for selected courses to help you decide.",
  },
]

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className="min-h-screen bg-[#12141D] text-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 text-violet-500">Frequently Asked Questions</h1>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-gray-800 rounded-lg overflow-hidden">
              <button
                className="w-full text-left p-4 focus:outline-none focus:ring-2 focus:ring-violet-500 flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                <span className="font-medium text-lg">{faq.question}</span>
                {openIndex === index ? (
                  <FaChevronUp className="w-5 h-5 text-violet-400 transition-transform duration-300" />
                ) : (
                  <FaChevronDown className="w-5 h-5 text-violet-400 transition-transform duration-300" />
                )}
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openIndex === index ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="p-4 bg-gray-700">
                  <p className="text-gray-300">{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQ

