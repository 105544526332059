import React, { useEffect } from 'react'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll(0, 0);

    }, []);
    return (
        <>
            <div>
                <div className='text-center font-bold text-3xl pt-10 bg-purple-900 text-white selection:bg-purple-200 selection:text-black'>Privacy Policy</div>

                <div className='md:p-24 p-12 flex flex-col gap-3 bg-gradient-to-t from-black via-slate-800 to-purple-900 text-white selection:bg-purple-200 selection:text-black'>

                    <div className='flex justify-center'>
                        JUZZ-IT (along with its subsidiaries, the “Company”, “us”, “we”, or “our”)understands and recognizes your right and/or that of
                        the Minor User (as defined in the Terms) to confidentiality and is committed to protecting and preserving your privacy
                        and/or that of the Minor User. Pursuant to such commitment, the Company has developed this Privacy Policy for the
                        handling of your Information and/or that of a Minor User, as defined and
                        provided hereunder.

                        <br /><br />
                        This Privacy Policy applies to our Users and/or Minor Users, employees and
                        consultants and governs the use of the

                        Company’s Services on its Website and
                        Platform and at its Offline Centres as defined in the JUZZ-IT terms and conditions
                        available a ( https://juzzit.in/ )(the “Terms”)and any new feature or tool which may
                        be added to the current Website and Platform.
                        <br />
                        <br />

                        Subject to the provisions of this Privacy Policy, the applicable Terms and all
                        applicable laws, the Company may collect, receive, store, deal in, handle and/or
                        share Personal Information (as defined hereinafter) including Sensitive Personal
                        Data (as defined hereinafter) and Non-Personal Information (as defined hereinafter)

                        pertaining to the Users and/or Minor Users online and/or offline use of the Services.
                        <br /><br />
                        This Privacy Policy outlines the Information the Company may process and/or use
                        to improve the User and/or Minor User experience while using the Services.
                        You (on behalf of yourself and/or a Minor User) must be in a position to provide
                        informed consent prior to providing any Information required for the use of the
                        Services. You agree that by purchasing our Services, accessing our Website and
                        Platform or attending Courses at our Offline Centres, you (on behalf of yourself
                        and/or a Minor User) consent to us collecting, receiving, storing, possessing,

                        dealing with, sharing and/or handling your Information in accordance with the
                        terms of this Privacy Policy. Accordingly, you (on behalf of yourself and/or a Minor
                        User) are required to read and understand this Privacy Policy, along with such other
                        Terms, as may be applicable to your use or that of the Minor User’s use of the
                        Services, before submitting any Information. Please note that your and/or a Minor
                        User’s access to the Services is conditional upon your consenting to this Privacy
                        Policy. Please further note that if you are a Minor User, please immediately stop
                        using the Services until your parent or legal guardian approves and consents to the

                        usage of the Services in accordance with the Privacy Policy and the applicable
                        Terms relating to use of the Services.

                    </div>

                    <div className='text-start font-bold text-2xl'>DEFINITIONS</div>
                    For the purposes of this Privacy Policy, unless the context otherwise requires:
                    <br />
                    <br />
                    <ol className='list-[lower-roman]'>
                        <li>
                            “Account” shall mean the online account created on the Platform by the User for
                            use of the Services (as defined below).

                        </li>
                        <br />
                        <li>
                            “Course” shall mean our programs offered remotely through either our Platform,
                            pre-recorded multimedia and audio-visual content, live streamed classes on third
                            party applications and/or our programs

                            offered physically at our Offline Centres.

                        </li>
                        <br />
                        <li>
                            “Course Material” shall mean the information provided by Bluink360 Solutions to accompany a
                            Course as part of the Services which is available on our Platform and includes
                            assessment tests and examinations.

                        </li>

                        <br />
                        <li>
                            “Force Majeure Event” shall mean any event that is beyond the reasonable control
                            of the Company and shall include, without limitation, sabotage, fire, flood,
                            explosion, act of God, civil commotion, pandemics, strikes or industrial action of
                            any kind, riots, insurrection, war, acts of government, computer hacking,
                            unauthorized access to your mobile or storage device, crashes, breach of security

                            and encryption, power or electricity failure or unavailability of adequate power or
                            electricity, or any unauthorized act of third party service provider engaged by the
                            Company in accordance with this Privacy Policy.

                        </li>
                        <br />
                        <li>
                            “Information” shall mean collectively Non-Personal Information (as defined below)
                            and Personal Information (as defined below).

                        </li>
                        <li>
                            “Non-Personal Information” shall mean and include:
                        </li>
                        <li>
                            the type of device used by the User and/or the Minor User;
                        </li>
                        <li>
                            the website that referred you and/or a Minor User to the Platform and the exit

                            page;

                        </li>
                        <li>
                            unique identifiers, which are used to collect and store information about an app or
                            device, such as preferred language, time zone, screen size, browser and other
                            settings.

                        </li>
                        <li>
                            “Offline Centre” means a physical premises operated by the Company to deliver
                            offline classes.

                        </li>
                        <li>
                            “Personal Information” means any information relating to the Users and/or Minor
                            Users which, either directly or indirectly, in combination with other information
                            available or likely to be available with the Company, is capable of identifying such

                            Users and/or Minor Users, and includes Sensitive Personal Data (as defined below)
                            of the User and/or Minor User. Personal Information shall include, for example,
                            name, mobile number, the internet protocol address and/or geographic location of
                            the User and/or Minor Users, any files consisting of audio, visual, and/or audio?visual information provided by the User and/or Minor Users to the Company, or
                            which the Company gains access to pursuant to or in connection with the User
                            and/or the Minor User using the Services.

                        </li>
                        <li>
                            “Platform” means the Website and any

                            other feature or tool provided and operated by Juzz-IT, including any application that may be launched by JUZZ-IT.


                        </li>
                        <li>
                            “Services” means the provision of the Course and/or the Course Material together
                            with such other services as agreed from time to time and purchased by you (on
                            behalf of yourself and/or the Minor User) and availed remotely through either our
                            Platform, pre-recorded multimedia and audio-visual content, live streamed classes on third party application and/or physically at our Offline Centres.


                        </li>
                        <li>
                            “Sensitive Personal Data” means such personal information relating to passwords, financial information such as bank account or credit card or other

                            payment instrument details, physical, mental or physiological health condition, sexual
                            orientation, medical records and history, biometric information, etc.
                            “Third Party” means any person or entity other than you and/or the Minor User, or
                            the Company.


                        </li>
                        <li>
                            “User” (or, “you”, “your”, and “yourself”) means any individual who is a natural
                            person aged 18 (eighteen) years or above, and who is competent and capable of
                            contracting within the meaning of the Indian Contract Act, 1872 and accesses our
                            Website and/or purchases the Services. Such an individual, where applicable,
                            includes the parent/legal guardian or

                            any other authorized individual who facilitates
                            the use of the Services for a Minor User.


                        </li>

                    </ol>




                    <div className='text-start font-bold text-xl'>INFORMATION COLLECTION</div>
                    <div>
                        When Do We Collect Information and What Types of Information do we Collect.
                        The Company obtains the Information (including Personal Information) when you or
                        a Minor User purchases, downloads, registers, accesses or uses any of the Services
                        provided by the Company. When you or a Minor User visits our Website or Offline
                        Centres or accesses our Platform and you (on behalf of yourself or a Minor User)

                        voluntarily fill one of our contact forms, via an in-person, chat or phone session, or
                        as part of a purchase of one of our Courses or register on our Website, you (on
                        behalf of yourself and/or a Minor User) generally provide:
                        your and/or a Minor User’s name, gender, date of birth, guardian’s name (if
                        applicable), email address, phone number and mailing address;
                        information about your business, such as company name, company size, business
                        type;  your JUZZ-IT account details which may include billing information, and account
                        password; and  other information you (on behalf of yourself and/or a Minor User) may provide at our Offline Centres, or enter or upload into our system when using the Platform.

                    </div>
                    <br />
                    The above-mentioned information is necessary to allow the Company to operate the
                    Platform, deliver Courses at our Online/Offline Centres and provide the Services to Users
                    and/or Minor Users. Without it, we may not be able to provide you and/or a Minor
                    User with all the requested Services, which could result in you or a Minor User not
                    being able to use the Platform or avail our Services (or any part thereof).
                    In addition, the Platform may collect

                    certain Information automatically, including,
                    but not limited to:

                    the type of handheld device used;
                    the handheld device’s unique device ID;
                    the IP address of such handheld device;
                    such handheld device’s operating system; and
                    the type of internet browsers you and/or a Minor User uses.
                    We may collect usage information about your and/or a Minor User’s use of our
                    Services, including but not limited to:
                    the number of tests, Courses you and/or a Minor User has attempted on the
                    Platform and/or at our Offline Centres;
                    the number of videos you and/or a Minor User has viewed;
                    the Courses you and/or a Minor User

                    participates in; and
                    the amount of time spent to complete a Course on the Platform and/or at our
                    Offline Centres.

                    <br /><br />
                    This enables us to better tailor educational experiences that are most appropriate
                    for you and/or a Minor User.
                    What Information is Collected through Third Parties?
                    We also obtain data from third parties. We protect data obtained from third parties
                    according to the practices described in this Privacy Policy, plus any additional
                    restrictions imposed by the source of the data. These third-party sources vary over
                    time, but have included:
                    <br />
                    data brokers from which we purchase demographic data to supplement the data we
                    collect;

                    social networks when you (on behalf of yourself and/or a Minor User) grant
                    permission to make your data public on these networks including permission to
                    access your and/or a Minor User’s data on one or more of these networks;
                    enterprise customers where you are an employee availing our Services;
                    existing or past Users and/or Minor Users;
                    service providers that help us determine a location based on your and/or a Minor
                    User’s IP address in order to customize certain products to your and/or a Minor
                    User’s location;


                    partners with which we may offer co-branded services or engage in joint marketing
                    activities; and
                    publicly available sources such as open government databases or other data in the
                    public domain.
                    We may also receive Personal Information about you and/or Minor Users whose
                    access to our Services is being facilitated by you from other sources like postal
                    mail, telephone or fax. We may add this information to the information we have
                    already collected from you and/or a Minor User via the Platform or at our Offline
                    Centres in order to improve the products

                    and Services we provide.
                    Technologies we may use.
                    <br /><br />
                    In order to provide a personalized learning and high-quality experience for our
                    Users and/or Minor Users, we may use various technologies that automatically
                    record certain technical information from the browser or device that you or a Minor
                    User uses when you and/or a Minor User visits or accesses the handheld device,
                    reads our emails, uses our Services or otherwise engages with us. This information
                    is typically collected through a variety of tracking technologies, including cookies,
                    web beacons, flash objects, log files, and similar technology (collectively,

                    “Tracking
                    Technologies”). These Tracking Technologies collect information about how you
                    and/or a Minor User use the Services (such as the pages viewed, the links clicked,
                    and other actions taken on the Services). We may collect analytics data, or use
                    third party analytics tools, to help us measure traffic and usage trends for the
                    Platform and to understand more about the demographics of our Users and/or
                    Minor Users. We may also work with third party partners to employ technologies
                    that assist us in providing the Services and enhance the User and/or Minor User
                    experience. Any third-party services

                    used by the Company shall be governed by
                    their respective terms of use and privacy policy, as well as any applicable laws.
                    When you and/or a Minor User uses the Services, any Information you (on behalf of
                    yourself and/or a Minor User) provide may be sent to the Company, and such
                    Information collected by the Company may be stored by the Company on its
                    servers or any cloud-based platform operated by any third party, in accordance
                    with applicable laws and their privacy policy.
                    <br /><br />

                    <div className='text-start font-bold text-xl'>COOKIE POLICY</div>
                    <br />
                    <div className='text-start font-bold text-xl'>What are Cookies?</div>

                    <br />
                    A cookie is a small file which asks

                    permission to be placed on a computer’s or
                    device’s hard drive. Once you (on behalf of yourself and/or a Minor User) agree, the
                    file is added and the cookie helps analyze web traffic or lets you and/or a Minor
                    User know when you and/or a Minor User visit a particular site. Cookies allow web
                    applications to respond to you and/or a Minor User as an individual. The web
                    application can tailor its operations to your and/or a Minor User’s needs, likes and
                    dislikes by gathering and remembering information about your and/or a Minor
                    User’s preferences.
                    <br />
                    <div className='text-start font-bold text-xl'>How do we Use Cookies?</div>



                    We use traffic log cookies to identify which pages are being used. This helps us
                    analyze data about webpage traffic and improve the Platform in order to tailor it to
                    customer needs. We only use this information for statistical analysis purposes and
                    then the data is removed from the system.
                    Overall, cookies help us provide you and/or a Minor User with a better Platform, by
                    enabling us to monitor which webpages you find useful and which you do not. A
                    cookie in no way gives us access to your computer or any device or any Information
                    about you or a Minor User, other than the data shared with us. You (on behalf

                    of
                    yourself and/or a Minor User) can choose to accept or decline cookies.
                    How to Manage Cookies?
                    Most web browsers automatically accept cookies, but you (on behalf of yourself
                    and/or a Minor User) can usually modify your browser setting to decline cookies, if
                    you prefer. Please refer to your web-browser provider or system guide for detailed
                    information on disabling cookies. However, in case you choose to disable the
                    cookies, you and/or a Minor User may be prevented from taking full advantage of
                    the Platform.
                    <br /><br />
                    <div className='text-start font-bold text-xl'>PURPOSES OF COLLECTING, STORING AND USING YOUR INFORMATION AND THE
                        MANNER OF SUCH USAGE</div>

                    <br />

                    The Company uses, stores, and processes Information, including Personal
                    Information, about you and/or a Minor User for the following purposes:
                    to use the Information to provide, develop, enhance and improve the Services,
                    conduct relevant research, create internal guidelines for content creation, and
                    record and register geographic influences of the Services and to offer targeted
                    advertisements to you and/or a Minor User and to create publicity material to

                    be
                    shared with third parties.
                    <br /><br />
                    the Information collected is used for internal operational purposes such as
                    responding to customer service requests, administering your account, enforcing
                    content license restrictions, determining preferred language and providing and
                    improving the Services.
                    to offer Users and/or Minor Users personalized content based on the search history,
                    and key words used in navigating the Platform, as well as usage history of the
                    Platform.
                    <br /><br />
                    to facilitate your transactions with other Users and/or Minor Users when you and/

                    or
                    a Minor User use our Service(s) and perform statistical analyses of User and/or
                    Minor User behavior and characteristics at an aggregate level in order to measure
                    interest in and use of the various areas of the Platform. <br /><br />
                    To provide Information collected on the Platform and/or at our Offline Centres, to
                    third parties, including those who are working with the Company (i) to operate
                    (including analyzing data), improve and enhance the features and experiences on
                    the Services, including providing relevant government-approved certificates, and
                    financial assistance to Users, and (ii) for relevant research and analytics on the

                    Services or in connection with the services provided by such third parties, which
                    may be by way of providing access to the Information on the Company’s server
                    located in India and/or transferring the Information to the servers of such third
                    parties located in India.
                    <br /><br />
                    Further, notwithstanding anything to the contrary contained herein, you may (on
                    behalf of yourself and/or a Minor User) at any time choose to provide a written
                    notice to the Company to revoke the consent given by you (on behalf of yourself
                    and/or a Minor User) to share information with third parties who are working with
                    the Company. Upon receipt of such notice, we may not be able to continue to

                    provide you and/or a Minor User with the Services (or any part thereof). Please
                    note that the Company shall not be responsible or liable in any manner whatsoever
                    for any actions of any third parties that receive the Information.
                    <br /><br />
                    We may contact you and/or a Minor User via tele-call, email, SMS, or other forms of
                    communication for, inter alia, informing you of new service/ product offerings,
                    offering you targeted advertisements of our Services, and collecting feedback in
                    relation to the Platform and the use of the Services, or sharing information with you
                    and/or a Minor User about certain promotions or features in relation to the Platform

                    and our Services. You and/or a Minor User may receive such communication when
                    you (on behalf of yourself and/or a Minor User) have registered as a User and/or a
                    Minor User on the Platform or at our Offline Centres. By agreeing to this Privacy
                    Policy, you (on behalf of yourself and/or a Minor User) give us explicit permission to
                    do so. Irrespective of the fact if you (on behalf of yourself and/or a Minor User)
                    have also registered yourself under DND or DNC or NCPR service, you (on behalf of
                    yourself and/or a Minor User) still authorize us to give you a call for the above?mentioned purposes.
                    <br /><br />
                    if we are involved in a merger, acquisition, due diligence, reorganization,
                    bankruptcy or liquidation where the Company’s business will not continue as a
                    going concern, receivership, sale of the Company’s assets, or transition of the
                    Services to another provider, then your Information and of a Minor User may be
                    transferred as part of such a transaction as permitted by applicable laws and/or
                    contract. The use of your Information and that of a Minor User following any of
                    these events will be governed by the policies in effect at the time the applicable
                    Information was collected.

                    <div className='text-start font-bold text-xl'>HOW WE STORE AND PROCESS YOUR INFORMATION</div>



                    Your and/or a Minor User’s Information will primarily be stored in electronic form.
                    However, certain data may also be stored in physical form.
                    We may enter into agreements with third parties (within or outside of India) to
                    store or process your and/or a Minor User’s Information, subject to applicable laws.
                    These third parties may have their own security standards to safeguard this
                    Information and we will, on a commercially reasonable basis, require such third
                    parties to adopt reasonable security standards to safeguard this Information, as per
                    the requirements of applicable laws. Please note that by agreeing to this

                    Privacy
                    Policy, you (on behalf of yourself and/or a Minor User) hereby agree to and permit
                    the sharing your Information with such third parties for storage and processing
                    purposes.
                    <br />
                    <br />
                    Currently, we plan to retain User and/or Minor User Information while an Account is
                    active and for such time period thereafter as is reasonably required for the
                    purposes for which the Information may lawfully be used or is otherwise required
                    under applicable laws for the time being in force. We may alter this practice
                    according to legal and business requirements. For example: we retain User and/or
                    Minor User information for a period of

                    180 (One Hundred and Eighty) days after
                    cancellation or termination of such User and/or Minor User accounts to comply with
                    applicable laws.
                    <br />
                    <div className='text-start font-bold text-xl'>WHO WE SHARE AND DISCLOSE YOUR INFORMATION TO</div>


                    You (on behalf of yourself and/or a Minor User) hereby unconditionally agree and
                    permit that the Company may transfer, share, disclose or part with all or any of
                    your and/or a Minor User’s Information, within and outside of India to <br /><br />(i) various
                    group entities, third party service providers (without any reference to persona or
                    identity), general public and the National Skills Development Corporation, for one
                    or more of the purposes set forth in

                    Clause 5, <br /><br /> (ii) government agencies upon
                    directions of such agency, or <br /><br />(iii) to any other third party as may be required under
                    applicable laws. In case of any Personal Information so transferred, shared,
                    disclosed or parted with, we will seek to, as may be required under applicable laws,
                    contractually require the receiving parties of the Information to ensure the same
                    level of data protection adopted by the Company is adhered to by the receiving
                    party, subject to applicable laws.
                    <br /><br />
                    You (on behalf of yourself and/or a Minor User) acknowledge and agree that, to the
                    extent permissible under applicable laws, it is adequate that when the

                    Company
                    transfers your and/or a Minor User’s Information, in accordance with the provisions
                    of this Privacy Policy, to any other entity within or outside your country of
                    residence, the Company will seek to place contractual requirements on the
                    transferee, which will oblige the transferee to adhere to the provisions of this
                    Privacy Policy.
                    <br /><br />
                    You (on behalf of yourself and/or a Minor User) acknowledge and agree that the
                    Company may transfer/disclose/share Information (other than Sensitive Personal
                    Data) to third parties who support our business, including such as providing

                    technical infrastructure services, analyzing how our Services are used, measuring
                    the effectiveness of advertisements, providing customer /buyer services, facilitating
                    payments, conducting academic research and surveys, or your employer/JUZZ-IT authorized training partner who pays for your subscription. The Company will seek
                    to place contractual requirements on such transferees to adhere to confidentiality
                    obligations consistent with this Privacy Policy.
                    You (on behalf of yourself and/or a Minor User) acknowledge and agree that the
                    Company may also transfer/disclose/

                    share information with other third parties such
                    as non-banking financial institutions, banks, credit facility providers, payment
                    gateways and/or aggregators, digital lending apps/platforms, lending service
                    providers, financial services intermediaries and / or any other entity facilitating
                    financing and/or payment related services, in order to facilitate the provision of
                    monetary assistance to Users. The Company will seek to place contractual
                    requirements on such transferees to adhere to confidentiality obligations and
                    security measures consistent with this Privacy Policy.
                    <br /><br />
                    The Company may also disclose Personal Information with such third

                    parties, or
                    otherwise to enforce this Privacy Policy, or when the Company believes it is
                    necessary to do so in order to investigate, prevent, or take action regarding illegal
                    activities, suspected fraud, situations involving potential threats to the physical
                    safety of any person, including any User and/or a Minor User’s, violations of various
                    terms and conditions or our policies, including this Privacy Policy.
                    We reserve the right to disclose your and/or a Minor User’s Information when
                    required to do so by applicable laws or regulations, or under any legal obligation or
                    order under applicable laws, or in response to a request from a law

                    enforcement or
                    governmental agency or judicial, quasi-judicial or any other statutory or
                    constitutional authority, or to protect our rights, safety and property, or establish or
                    exercise our legal rights or defend against legal claims.
                    <br /><br />
                    <div className='text-start font-bold text-xl'>HOW WE SECURE YOUR INFORMATION</div>
                    <br />

                    Security Practices in place.
                    The security of your and/or a Minor User’s Personal Information is important to us.
                    The Company strives to ensure the security of this Personal Information and to
                    protect it against unauthorized access or unauthorized alteration, disclosure, loss,
                    misuse or destruction. The Company

                    adopts reasonable security practices and
                    procedures, including physical, managerial, operational and technical security
                    measures, as mandated under applicable laws for the protection of your and/or a
                    Minor User’s Personal Information. Provided that your (on behalf of yourself and/or
                    a Minor User) right to claim damages shall be limited to the right to claim only
                    statutory damages under the Information Technology Act, 2000 and you (on behalf
                    of yourself and/or a Minor User) hereby waive and release the Company and all the
                    Company’s entities from any claim of

                    damages under contract or under tort, to the
                    extent permissible under applicable laws. If we become aware that your and/or a
                    Minor User’s Personal Information has been accessed or disclosed in a manner not
                    in accordance with this Privacy Policy, we will use reasonable efforts to notify you
                    (on behalf of yourself and/or a Minor User) of the nature and extent of such access
                    or disclosure (to the extent we know of such access or disclosure) as soon as
                    reasonably possible and as permitted by, and in accordance with, applicable laws.
                    <br /><br />
                    <div className='text-start font-bold text-xl'>Limitation of Access to Information.</div>


                    <br /><br />
                    The Company limits access to Information to those employees, agents, contractors
                    and other third parties on a need-to-know basis, i.e., those who need the access in
                    order to fulfill the tasks and duties relating to Service provision. All service
                    providers are permitted to process the Information based on the Company’s
                    instructions, and they are subject to a duty of confidentiality and are required to be
                    compliant and demonstrate compliance with applicable data protection laws.
                    Confidentiality Obligations.
                    Information shared by the Company with third parties are subject to confidentiality
                    agreement which, inter alia, provides

                    that such third parties shall not disclose the
                    Information further unless such disclosure is for the purposes set forth in Clause 5.
                    However, the Company is not responsible for any breach of security or for any
                    actions of any third parties that receive your and/or a Minor User’s Personal
                    Information.
                    Limitation of our Liability.
                    Notwithstanding anything contained in this Privacy Policy or elsewhere, to the
                    extent permissible under applicable laws, the Company shall not be held
                    responsible for any loss, damage or misuse of your and/or a Minor User’s Personal
                    Information, if such loss, damage or

                    misuse is attributable to a force majeure
                    event.
                    While we will endeavor to take all reasonable and appropriate steps to keep secure
                    any Personal Information which we hold and prevent unauthorized access or
                    disclosures, you (on behalf of yourself and/or a Minor User) acknowledge that the
                    internet or computer networks are not fully secure and that we cannot provide any
                    absolute assurance regarding the security of your and/or a Minor User’s Personal
                    Information.
                    <br /><br />
                    <div className='text-start font-bold text-xl'>YOUR RIGHTS</div>
                    <br /><br />

                    You (on behalf of yourself and/or a Minor User) may exercise any of the

                    rights
                    described in this section by sending an email to Education@juzzit.in. Please note
                    that we may ask you (on behalf of yourself and/or a Minor User) to verify your
                    and/or a Minor User’s identity before taking further action on your request.
                    Rectification of Inaccurate or Incomplete Information.
                    You (on behalf of yourself and/or a Minor User) may contact us at the email
                    address provided above to review your Personal Information and/or update, correct
                    or amend inaccurate, deficient or incomplete Personal Information, and we will
                    make reasonable efforts to update the

                    same (to the extent feasible for the
                    Company).
                    Please note that while you (on behalf of yourself and/or a Minor User) have the
                    right to ask us to correct inaccurate, deficient or incomplete Personal Information
                    concerning you and/or a Minor User (and which you cannot update yourself within
                    your and/or a Minor User’s Account), you are responsible for keeping your Personal
                    Information up-to-date, to the extent you (on behalf of yourself and/or a Minor
                    User) can update the same within your and/or a Minor User’s Account.

                    <br /><br />
                    <div className='text-start font-bold text-xl'>Data Retention and Erasure.</div>
                    <br /><br />

                    If you no longer want the Company to use your and/or the Minor User’s

                    Personal
                    Information to provide the Services (or any part thereof) to you and/or a Minor
                    User, or you wish to close your and/or a Minor User’s Account, you (on behalf of
                    yourself and/or a Minor User) can request that we erase this Personal Information
                    and close your and/or a Minor User’s Account. Provided, however, any requests for
                    erasure, or closure of Account, will be acted upon in accordance with applicable
                    laws and may adversely impact the Company’s ability to provide the Services to
                    you and/or a Minor User. You (on behalf of yourself and/or a Minor User) hereby
                    agree and acknowledge that a mere

                    request for erasure does not guarantee an
                    immediate erasure of Personal Information, and all such requests will be acted upon
                    in accordance with applicable laws.
                    Please note that if you (on behalf of yourself and/or a Minor User) request the
                    erasure of your Personal Information:
                    we may retain some of your and/or a Minor User’s Personal Information as
                    necessary for protecting our legitimate business interests, such as fraud detection
                    and prevention and enhancing safety. For example, if we suspend an Account for
                    fraud or safety reasons, we may retain certain information from that Account

                    prevent that User from opening a new Account in the future. <br /><br />
                    We retain User and/or Minor User information for a period of 180 (One Hundred and
                    Eighty) days after cancellation or termination of such User accounts to comply with
                    applicable laws.
                    we will not be required to delete any information which has been de-identified or
                    disassociated with personal identifiers such that it can no longer be used to
                    reasonably identify a particular individual.
                    the Company will not be able to provide you and/or the Minor User with the
                    Services (or any part thereof) and you and/or a Minor User should cease and

                    desist
                    from using the Services.
                    Consent; Withdrawing Consent and Restriction of Processing.
                    In the event you do not consent to sharing your and/or a Minor User’s Personal
                    Information with us for provision of the Services (or any part thereof), we reserve
                    the right to restrict or deny the provision of such Services (or any part thereof).
                    Where you have provided your consent to the processing of your and/or a Minor
                    User’s Personal Information by the Company you may withdraw your consent by
                    sending a communication to our email address at education@juzzit.in specifying in
                    reasonable detail the consent you are

                    withdrawing (on behalf of yourself and/or a
                    Minor User). Please note that the withdrawal of your consent (on behalf of yourself
                    and/or a Minor User) does not affect the lawfulness of any processing activities
                    based on such consent before its withdrawal. If you withdraw your consent (on
                    behalf of yourself and/or a Minor User) and restrict processing of any Personal
                    Information, the Company may not be able to provide you and/or the Minor User
                    with the Services (or any part thereof) and you and/or the Minor User may be
                    required to cease to use the Services (or any part thereof).
                    <br /><br />
                    Please note that if you withdraw your

                    consent to process your and/or a Minor User’s
                    Personal Information in accordance with the Privacy Policy, we will not be required
                    to delete any such information which has been anonymized, de-identified or
                    disassociated with personal identifiers such that it can no longer be used to
                    reasonably identify a particular individual.
                    <br />
                    <div className='text-start font-bold text-xl'>LINKS TO THIRD PARTY APPS AND WEBSITES</div>


                    The Platform and any communication sent to you and/or a Minor User may
                    contain/display third party advertisements, contents, applications, and links to
                    other websites/mobile applications of interest. However, once you and/or a

                    Minor
                    User has used any of these links to, or through any other means leave the Platform,
                    you and/or a Minor User should note that we do not have any control over that
                    other website(s)/mobile application(s). You (on behalf of yourself and/or a Minor
                    User) agree and acknowledge that these websites/mobile applications are operated
                    by third parties and are not controlled by, or affiliated to, or associated with us
                    unless expressly specified. We are not responsible for any form of transmission,
                    whatsoever, received by you and/or the Minor User from any such third party.
                    Accordingly, we do not make any

                    representations concerning the privacy practices
                    or policies of such third parties or terms of use of such websites or mobile
                    applications, nor do we control or guarantee the accuracy, integrity, or quality of
                    the information, data, text, software, music, sound, photographs, graphics, videos,
                    messages or other materials available on such websites or mobile applications.
                    Therefore, we cannot be responsible for the protection and privacy of any
                    information, which you and/or the Minor User provide whilst visiting such
                    websites/mobile applications and this Privacy Policy does not govern such
                    websites/mobile applications. You (on behalf of yourself and/or a Minor User)

                    should exercise caution and look at the privacy statement applicable to the
                    website(s)/mobile application(s) in question. The information provided by you
                    and/or a Minor User to such third-party websites/mobile applications shall be
                    governed in accordance with the privacy policies of such websites/mobile
                    applications and it is recommended that you (on behalf of yourself and/or a Minor
                    User) review the privacy policy on any such websites and/or mobile applications
                    prior to using such websites/mobile applications.
                    <br /><br />
                    <div className='text-start font-bold text-xl'>GRIEVANCE OFFICER</div>

                    <br />
                    If you find any discrepancies or have any grievances in relation to the Services, or
                    the collection, processing, storage, use,

                    disclosure and transfer of your and/or a
                    Minor User’s Personal Information under this Privacy Policy or any other applicable
                    terms and conditions or policies of the Company, as may be applicable, please
                    contact the following:

                    <br /><br />
                    <div className='font-bold flex gap-2 flex-col'>

                        Name: MD Riyaz

                        <div className='flex gap-2'>

                            E-mail: <a href="mailto:education@juzzit.in">education@juzzit.in</a>
                        </div>
                        Working Days: Mon – Fri

                        Working Hours: 10 am – 6 pm
                    </div>
                    <br />
                    We will strive to address your feedback and concerns in a timely and effective
                    manner and within the time period prescribed under applicable law. The details of
                    the grievance officer may be changed by us from time to time by updating this
                    Privacy Policy.
                    <br />
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
