import React from "react";
import { FaCheck, FaGraduationCap, FaBriefcase, FaPlus } from "react-icons/fa";

function WhyChooseUs() {
  const features = [
    {
      icon: <FaCheck size={30} color="#fff" />,
      title: "Trusted Bootcamp Since 2015",
      description: "Proin primis diam pede iaculis condimentum lorem maximus fusce massa ridiculus urna.",
    },
    {
      icon: <FaGraduationCap size={30} color="#fff" />,
      title: "30,000+ Successful Digital Careers of Our Alumni",
      description: "Proin primis diam pede iaculis condimentum lorem maximus fusce massa ridiculus urna.",
    },
    {
      icon: <FaBriefcase size={30} color="#fff" />,
      title: "1,000+ Hiring Partners and Countless Job Opportunities",
      description: "Proin primis diam pede iaculis condimentum lorem maximus fusce massa ridiculus urna.",
    },
    {
      icon: <FaPlus size={30} color="#fff" />,
      title: "Lifelong Career Support for Our Alumni",
      description: "Proin primis diam pede iaculis condimentum lorem maximus fusce massa ridiculus urna.",
    },
  ];

  return (
    <div
      className="py-12"
      style={{
        background: "radial-gradient(circle, rgba(128,90,213,0.5) 0%, rgba(13,17,23,1) 100%)",
        color: "#fff",
      }}
    >
      <div className="container mx-auto text-center px-4 lg:px-12">
        <h3 className="text-3xl font-bold mb-6">Why Choose Us?</h3>
        <p className="text-gray-300 mb-12 text-lg leading-relaxed">
        Join a trusted community where learners achieve their goals and transform their careers through expert guidance and support.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-6 bg-gradient-to-b from-[#242636] to-[#1a1c29] rounded-lg shadow-lg"
            >
              <div
                className="w-16 h-16 mb-4 flex items-center justify-center bg-purple-500 rounded-full"
              >
                {feature.icon}
              </div>
              <h4 className="text-xl font-semibold mb-2">{feature.title}</h4>
              <p className="text-gray-400 text-sm">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
