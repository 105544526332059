import { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Added useLocation
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";

const Login = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectedRole, setSelectedRole] = useState(""); // Remember student/teacher role
    const navigate = useNavigate();
    const location = useLocation(); // Get location
    // Extract the "redirect" query parameter from the URL if it exists
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get("redirect");

    useEffect(() => {
        window.scroll(0, 0);

    }, []);

    const handleNext = () => {
        if (step === 1) {
            if (selectedRole === "student") {
                setStep(2);
            } else if (selectedRole === "teacher") {
                setStep(3);
            }
        }
    };

    const handleLogin = async () => {
        if (!(email.includes("@" && ".") && email.length > 5 && email.length < 100)) {
            toast.error("Please enter a valid email address");
            return;
        }
        if (!(password.length > 5 && password.length < 100)) {
            toast.error("Password must be between 6 and 100 characters");
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/api/v1/auth/login`, {
                email,
                password,
                role: selectedRole, // Send selected role to the backend
            });

            // Check if the response contains the token and user data
            if (response.data.token && response.data.user) {
                // Save the token and user details in localStorage
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user", JSON.stringify(response.data.user));

                toast.success("Login successful!");

                // If a redirect URL is provided, navigate to that URL.
                // Otherwise, redirect based on user role.
                if (redirectUrl) {
                    navigate(redirectUrl);
                } else if (response.data.user.role === "student") {
                    navigate("/student-dashboard");
                } else if (response.data.user.role === "teacher") {
                    navigate("/teacher-dashboard");
                }
            } else {
                toast.error("Invalid login response from server");
            }
        } catch (error) {
            console.error(error.response?.data?.error || "Something went wrong");
            toast.error(error.response?.data?.error || "Login failed");
        }
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="flex items-center justify-center min-h-screen bg-slate-950 text-white p-3">
                <div className="bg-gradient-to-t from-slate-950 via-slate-800 to-purple-900 p-8 rounded-3xl shadow-lg w-96">
                    <h2 className="text-2xl font-bold text-white text-center uppercase">Log In</h2>
                    <p className="text-gray-400 text-center mt-2">To continue your learning journey.</p>

                    {/* Select student or teacher */}
                    {step === 1 && (
                        <>
                            <div className="flex flex-col items-center space-y-4">
                                <div className="flex gap-4">
                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <input
                                            type="radio"
                                            name="role"
                                            value="student"
                                            checked={selectedRole === "student"}
                                            onChange={() => setSelectedRole("student")}
                                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <span className="text-lg font-medium">Student</span>
                                    </label>
                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <input
                                            type="radio"
                                            name="role"
                                            value="teacher"
                                            checked={selectedRole === "teacher"}
                                            onChange={() => setSelectedRole("teacher")}
                                            className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                        />
                                        <span className="text-lg font-medium">Teacher</span>
                                    </label>
                                </div>
                                <button
                                    onClick={handleNext}
                                    className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}

                    {/* Login form for students */}
                    {step === 2 && (
                        <>
                            <input
                                type="email"
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600 px-3 mt-2 mb-4"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600 px-3 mt-2 mb-4"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                onClick={handleLogin}
                                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                            >
                                Login
                            </button>
                        </>
                    )}

                    {/* Login form for teachers */}
                    {step === 3 && (
                        <>
                            <input
                                type="email"
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600 px-3 mt-2 mb-4"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                className="w-full p-3 rounded-full bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600 px-3 mt-2 mb-4"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                onClick={handleLogin}
                                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                            >
                                Login
                            </button>
                        </>
                    )}

                    <div className="flex items-center my-4">
                        <hr className="flex-grow border-gray-600" />
                        <span className="px-2 text-gray-400">OR</span>
                        <hr className="flex-grow border-gray-600" />
                    </div>

                    <button
                        className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded-full cursor-pointer"
                    >
                        <FaGoogle /> LogIn with Google
                    </button>

                    <div className="flex justify-between items-center mt-4 text-sm">
                        <p>
                            <Link to="/resetpassword" className=" text-gray-400 cursor-pointer no-underline">Forgot Password</Link>
                        </p>
                        <p className="text-gray-400">
                            Don't have an account? <Link to="/signup" className="text-purple-400 cursor-pointer no-underline">SignUp</Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
